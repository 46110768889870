/* Ministries */

/* Ministries Page (Blocks)
-----------------------------------------------------------------------*/

.ministries-section {
    .section-content {
        &.ministry-blocks {
            padding-top: 60px;
            padding-bottom: 60px;
        }
        .ministry-block {
            width: 100%;
            padding: 0; margin-bottom: 10px;
            position: relative;
            float: left;
            transition: $transitions-primary;
            &:hover {
                z-index: 1;
                -webkit-transform: translateY(-6px);
                -moz-transform: translateY(-6px);
                -o-transform: translateY(-6px);
                transform: ranslateY(-6px);
                background-size: auto;
                .block-content {
                    h3, h3 a {
                        color: $color-primary;
                    }
                    .button {
                        opacity: 1;
                    }
                }
                .block-image {
                    border-top-left-radius: 24px;
                    border-top-right-radius: 24px;
                    border-bottom-left-radius: 0;
                    border-bottom-right-radius: 24px;
                    background-color: $color-primary;
                    box-shadow: $shadow-depth-5;
                    img {
                        opacity: .2;
                        -webkit-transform: scale(1.1);
                        -moz-transform: scale(1.1);
                        -o-transform: scale(1.1);
                        transform: scale(1.1);
                    }
                }
            }
            .block-content {
                h3 {
                    font-size: 1.5rem;
                }
                h4 {
                    font-size: 1.25rem;
                }
            }
            .block-content {
                width: 100%;
                padding: 20px 0;
                float: left;
                position: relative;
                z-index: 1;
                transition: $transitions-primary;
                .category {
                    margin-bottom: 5px;
                    display: block;
                    color: $color-medium-grey;
                    font-size: 0.75rem;
                    font-weight: $font-primary-semibold;
                    text-transform: uppercase;
                    letter-spacing: .06rem;
                }
                h3, h3 a {
                    margin-bottom: 5px;
                    color: $color-primary;
                    font-family: $font-primary;
                    font-size: 1.375rem;
                    font-weight: $font-primary-medium;
                    transition: $transitions-primary;
                }
                h4 {
                    margin-bottom: 10px;
                    color: $color-dark-grey;
                    font-family: $font-secondary;
                    font-size: 1.25rem;
                    font-weight: $font-secondary-regular;
                }
                .button {
                    opacity: 0;
                    i {
                        margin-left: 4px;
                    }
                }
            }
            .block-image {
                width: 100%;
                display: block;
                float: left;
                position: relative;
                z-index: 0;
                overflow: hidden;
                transition: $transitions-primary;
                -webkit-backface-visibility: hidden;
                backface-visibility: hidden;
                background-color: $color-light-tan;
                img {
                    -webkit-transition: all 1.2s cubic-bezier(0.215, 0.61, 0.355, 1);
                    transition: all 1.2s cubic-bezier(0.215, 0.61, 0.355, 1);
                    -webkit-transform: scale(1);
                    transform: scale(1);
                }
            }
        }
    }
    .section-footer {
        margin-top: 0;
    }
}

/* Ministries Details Page
-----------------------------------------------------------------------*/

/* Page Sections */

.signup-section {
    background-color: $color-white;
    .content-block {
        font-size: 1.125rem;
    }
}
