/* Variables */

/* Colors
-----------------------------------------------------------------------*/

/* Primary */

$color-primary: #DB8D1F;

/* Secondary */

$color-secondary: #51534a;
$color-secondary-red: #AF281E;
$color-secondary-blue: #163F52;
$color-secondary-green: #6E872D;
$color-secondary-yellow: #F3D100;
$color-dark-brown: #535446;
$color-light-brown: #968F87;
$color-light-tan: #F4F1EC;

/* Extra */
$color-black: #141314;
$color-white: #ffffff;
$color-dark-grey: #444;
$color-medium-grey: #878881;
$color-light-grey: #f4f3f4;

/* Font Styling
-----------------------------------------------------------------------*/

/* Primary Font Family */

$font-primary: 'proxima-nova', sans-serif;
$font-primary-light: 300;
$font-primary-regular: 400;
$font-primary-medium: 500;
$font-primary-semibold: 600;
$font-primary-bold: 700;

/* Secondary Font Family */

$font-secondary: 'adobe-garamond-pro', serif;
$font-secondary-regular: 400;


/* Animations / Transitions / Effects
-----------------------------------------------------------------------*/

$transitions-primary: all 300ms cubic-bezier(0.645, 0.045, 0.355, 1);
$shadow-depth-1: 0 2px 3px rgba(0,0,0,0.04);
$shadow-depth-2: 0 5px 10px rgba(0,0,0,0.04), 0 10px 20px rgba(0,0,0,0.12);
$shadow-depth-3: 0 10px 20px rgba(0,0,0,0.04), 0 20px 40px rgba(0,0,0,0.12);
$shadow-depth-4: 0 10px 20px rgba(0,0,0,0.04), 0 20px 40px rgba(0,0,0,0.12);
$shadow-depth-5: 0 20px 30px rgba(0,0,0,0.04), 0 30px 60px rgba(0,0,0,0.12);

/* Responsive Break Points
-----------------------------------------------------------------------*/

$responsive-large: 77.5rem; /* (1240px) */
$responsive-medium: 64.063rem; /* (1140px) */
$responsive-small: 40.06rem; /* (1140px) */
