/* Mouse */

.mouse-wrapper {
    width: 24px; height: 40px;
    position: absolute;
    margin: 0 auto;
    left: 0; bottom: 30px; right: 0;
    z-index: 100;
}

.mouse {
    position: relative;
    width: 24px; height: 40px;
    background-size: 100% 100%;
    border-radius: 100px;
    display: block;
    border: solid 1px rgba($color-white, .75);
    animation:
    colorSlide 5s linear infinite,
    nudgeMouse 5s ease-out infinite;
    &:after {
        width: 22px; height: 10px;
        margin: auto;
        content: "\ed1a";
        color: $color-white;
		font-family: 'Nucleo';
        font-size: 10px;
        text-align: center;
        position: absolute;
        top: 0; right: 0; bottom: 0; left: 0;
        animation: trackBallSlide 5s linear infinite;
    }
}

@keyframes trackBallSlide {
    0% {
        opacity: 1;
        transform: translateY(-10px);
    }
    6% {
        opacity: 1;
        transform: translateY(10px/4);
    }
    14% {
        opacity: 0;
        transform: translateY(10px*2);
    }
    15%, 19% {
        opacity: 0;
        transform: translateY(-10px);
    }
    28%, 29.99% {
        opacity: 1;
        transform: translateY(-10px);
    }
    30% {
        opacity: 1;
        transform: translateY(-10px);
    }
    36% {
        opacity: 1;
        transform:translateY(10px/4);
    }
    44% {
        opacity: 0;
        transform: translateY(10px*2);
    }
    45%, 49% {
        opacity: 0;
        transform: translateY(-10px);
    }
    58%, 59.99% {
        opacity: 1;
        transform: translateY(-10px);
    }
    60% {
        opacity: 1;
        transform: translateY(-10px);
    }
    66% {
        opacity: 1;
        transform: translateY(10px/4);
    }
    74% {
        opacity: 0;
        transform: translateY(10px*2);
    }
    75%, 79% {
        opacity: 0;
        transform: translateY(-10px);
    }
    88%, 100% {
        opacity: 1;
        transform:translateY(-10px);
    }
}

@keyframes nudgeMouse {
    0% { transform: translateY(0); }
    15% { transform: translateY(6px); }
    30% { transform: translateY(0); }
    50% { transform: translateY(6px); }
    60% { transform: translateY(0); }
    80% { transform: translateY(8px); }
    90% { transform: translateY(0); }
}
