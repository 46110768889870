// Reset & Third Party
@import "vendor/foundation";
@import "//maxcdn.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css";
@import "vendor/superfish";
@import "vendor/slidebars";
@import "vendor/flickity";
@import "vendor/prioritynav";
@import "vendor/hamburgers/hamburgers";
@import "vendor/hamburgers/base";
@import "vendor/classic";
@import "vendor/classic.date";
@import "@fancyapps/ui/dist/fancybox/fancybox.css";


// Helpers
@import "helpers/variables";
@import "helpers/functions";
@import "helpers/mixins";
@import "helpers/fonts";
@import "helpers/icons";
@import "helpers/mouse";

// Global Styling & Components
@import "components/global-styles";
@import "components/content-blocks";

// Layout
@import "layout/header";
@import "layout/footer";
@import "layout/global-structure";

// Pages
@import "pages/home";
@import "pages/interior";
@import "pages/events";
@import "pages/groups";
@import "pages/sermons";
@import "pages/ministries";
@import "pages/missions";
@import "pages/stories";
@import "pages/staff";
@import "pages/prayer";