/* Staff */

/* Staff Page (Blocks)
-----------------------------------------------------------------------*/

.staff-section {
    .section-content {
        &.staff-blocks {
            padding-top: 60px;
            padding-bottom: 60px;
        }
        .staff-block {
            width: 100%;
            padding: 0; margin-bottom: 10px;
            position: relative;
            float: left;
            transition: $transitions-primary;
            &:hover {
                z-index: 1;
                -webkit-transform: translateY(-6px);
                -moz-transform: translateY(-6px);
                -o-transform: translateY(-6px);
                transform: ranslateY(-6px);
                background-size: auto;
                .block-content {
                    h3, h3 a {
                        color: $color-primary;
                    }
                    .button {
                        opacity: 1;
                    }
                }
                .block-image {
                    border-top-left-radius: 24px;
                    border-top-right-radius: 24px;
                    border-bottom-left-radius: 0;
                    border-bottom-right-radius: 24px;
                    background-color: $color-primary;
                    box-shadow: $shadow-depth-5;
                    img {
                        opacity: .2;
                        -webkit-transform: scale(1.1);
                        -moz-transform: scale(1.1);
                        -o-transform: scale(1.1);
                        transform: scale(1.1);
                    }
                }
            }
            &.active {
                .block-content {
                    h3, h3 a {
                        color: $color-primary;
                    }
                    .button {
                        opacity: 1;
                    }
                }
                .block-image {
                    border-top-left-radius: 24px;
                    border-top-right-radius: 24px;
                    border-bottom-left-radius: 0;
                    border-bottom-right-radius: 24px;
                    box-shadow: $shadow-depth-5;
                    img {
                        opacity: .2;
                        -webkit-transform: scale(1.1);
                        -moz-transform: scale(1.1);
                        -o-transform: scale(1.1);
                        transform: scale(1.1);
                    }
                }
            }
            .block-content {
                width: 100%;
                padding: 20px 0;
                float: left;
                position: relative;
                z-index: 1;
                transition: $transitions-primary;
                .category {
                    margin-bottom: 5px;
                    display: block;
                    color: $color-medium-grey;
                    font-size: 0.75rem;
                    font-weight: $font-primary-semibold;
                    text-transform: uppercase;
                    letter-spacing: .06rem;
                }
                h3, h3 a {
                    margin-bottom: 5px;
                    color: $color-primary;
                    font-family: $font-primary;
                    font-size: 1.375rem;
                    font-weight: $font-primary-medium;
                    transition: $transitions-primary;
                    @media screen and (max-width: $responsive-medium) { font-size: 1.25rem; }
                }
                h4 {
                    margin-bottom: 10px;
                    color: $color-dark-grey;
                    font-family: $font-secondary;
                    font-size: 1.25rem;
                    font-weight: $font-secondary-regular;
                    @media screen and (max-width: $responsive-medium) { font-size: 1.125rem; }
                }
                .button {
                    opacity: 0;
                    i {
                        margin-left: 4px;
                    }
                }
            }
            .block-image {
                width: 100%;
                display: block;
                float: left;
                position: relative;
                z-index: 0;
                overflow: hidden;
                transition: $transitions-primary;
                -webkit-backface-visibility: hidden;
                backface-visibility: hidden;
                background-color: $color-light-tan;
                img {
                    -webkit-transition: all 1.2s cubic-bezier(0.215, 0.61, 0.355, 1);
                    transition: all 1.2s cubic-bezier(0.215, 0.61, 0.355, 1);
                    -webkit-transform: scale(1);
                    transform: scale(1);
                }
            }
        }
    }
    .section-footer {
        margin-top: 0;
    }
}

/* Staff Details Page (Modal)
-----------------------------------------------------------------------*/

.staff-modal {
    width: 100%; max-width: 1080px;
    padding: 0;
    outline: none;
    display: block;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    background-color: $color-light-tan;
    box-shadow: $shadow-depth-5;
    @media screen and (max-width: $responsive-medium) {
        max-width: 560px;
     }
    .modal-container {
        display: flex;
    }
    .modal-content {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        position: relative;
        z-index: 1;
        .staff-bio-image {
            width: 45%; height: 100%;
            align-self: center;
            position: absolute;
            left: 0; top: 0;
            background-size: cover;
    		background-repeat: no-repeat;
    		background-position: center center;
            position: relative;
            overflow: hidden;
            @media screen and (max-width: $responsive-medium) { width: 100%; height: 500px; }
            @media screen and (max-width: $responsive-small) { height: 400px; }
        }
        .staff-bio {
            height: 560px;
            padding: 40px;
            flex: 1;
            align-self: center;
            overflow: auto;
            @media screen and (max-width: $responsive-medium) { height: auto; overflow: inherit; }
            h2 {
                margin-bottom: 10px;
            }
            .staff-connect  {
                padding: 0; margin: 0;
                list-style: none;
                li {
                    display: inline-block;
                    strong {
                        margin-right: 5px;
                        color: $color-dark-brown;
                        font-size: 0.875rem;
                        font-family: $font-primary;
                        font-weight: $font-primary-semibold;
                        text-transform: uppercase;
                        letter-spacing: .08rem;
                    }
                    a {
                        width: 32px; height: 32px;
                        color: $color-primary;
                        font-size: 1rem;
                        text-align: center;
                        line-height: 32px;
                        display: inline-block;
                        border-radius: 200px;
                        background-color: $color-white;
                        &:hover {
                            color: $color-white;
                            background-color: $color-primary;
                        }
                    }
                }
            }
        }
    }
}
