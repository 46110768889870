/* Foundation Overrides
-----------------------------------------------------------------------*/

.row {
	max-width: $responsive-large;
}

.row .row {
	margin-left: -0.625rem;
	margin-right: -0.625rem;
}

.column,
.columns {
	padding-left: 0.625rem;
	padding-right: 0.625rem;
}

#page {
	overflow-x: hidden;
}

.align-middle {
	@media screen and (max-width: $responsive-medium) { align-items: flex-start; }
}

/* Top Section
-----------------------------------------------------------------------*/
[x-cloak] { display: none !important; }


/* Hero */

.page-header {
	width: 100%;
	position: relative;
	overflow: hidden;
	background-color: $color-black;
	background: linear-gradient(to right, $color-black, $color-dark-grey);
	&:before {
		width: 100%; height: 100%;
		position: absolute;
		z-index: 2;
		left: 0; top: 0;
		content: "";
		display: block;
		-webkit-box-shadow: inset 0px 120px 220px 0px rgba(0,0,0, .5);
		box-shadow: inset 0px 120px 220px 0px rgba(0,0,0, .5);
	}
	.row {
		height: 100vh;
		display: flex;
		align-items: center;
		position: relative;
		z-index: 2;
	}
	.header-content {
		padding: 180px 0;
		position: relative;
		z-index: 1;
		text-align: center;
		h1, h2, p {
			margin-bottom: 0;
			color: $color-white;
		}
		h1 {
			margin-bottom: 0;
			text-transform: uppercase;
			letter-spacing: .08rem;
		}
		h2 {
			margin-top: 10px;
			margin-bottom: 0;
			font-family: $font-primary;
			font-size: 1.25rem;
			font-weight: $font-primary-medium;
			text-transform: uppercase;
			letter-spacing: .1rem;
			@media screen and (max-width: $responsive-medium) { font-size: 1.25rem; }
			@media screen and (max-width: $responsive-small) { font-size: 1.125rem; }
		}
		p {
			margin-top: 10px;
			color: $color-white;
			font-size: 1.375rem;
			opacity: .8;
			@media screen and (max-width: $responsive-medium) { font-size: 1.25rem; }
			@media screen and (max-width: $responsive-small) { font-size: 1.125rem; }
			strong {
				font-weight: $font-primary-medium;
			}
			br {
				@media screen and (max-width: $responsive-small) { display: none; }
			}
		}
		.button {
			margin-top: 30px;
		}
	}
	.header-image,
	.header-video {
		width: 100%; height: 100%;
		position: absolute;
		left: 0; top: 0;
		z-index: 0;
		opacity: .7;
		background-size: cover;
		background-repeat: no-repeat;
		background-position: center center;
	}
	.header-image {
		opacity: .5;
	}
	.header-video {
		opacity: .7;
	}
}

/* Main Section
-----------------------------------------------------------------------*/

.main-section {
	background-color: $color-light-tan;
}

/* Page Section */

.page-section {
	padding: 100px 0;
	position: relative;
	@media screen and (max-width: $responsive-medium) { padding: 80px 0; }
	@media screen and (max-width: $responsive-small) { padding: 60px 0; }
	.section-header,
	.section-content,
	.section-footer {
		position: relative;
		z-index: 2;
	}
	.section-header {
		margin-bottom: 50px;
		h2 {
			margin-bottom: 0;
			font-size: 2.375rem;
			text-transform: uppercase;
			letter-spacing: .08rem;
            @media screen and (max-width: $responsive-medium) { font-size: 2rem;}
		}
		p {
			margin: 15px 0 0 0;
			color: $color-dark-grey;
			font-size: 1.125rem;
			@media screen and (max-width: $responsive-small) { font-size: 1rem;}
			strong, em {
				font-weight: $font-primary-medium;
			}
		}
	}
	.section-content {
		.content-block  {
			width: 100%;
			margin-bottom: 10px;
			display: block;
			float: left;
		}
		.content-card {
			display: flex;
			.content-block  {
				padding: 40px 30px 20px 30px; margin-bottom: 30px;
				position: relative;
				background-color: $color-white;
				transition: $transitions-primary;
				border-radius: 3px;
				&:hover {
					z-index: 1;
					border-top-left-radius: 24px;
					border-top-right-radius: 24px;
					border-bottom-left-radius: 0;
					border-bottom-right-radius: 24px;
	                -webkit-transform: translateY(-6px);
	                -moz-transform: translateY(-6px);
	                -o-transform: translateY(-6px);
	                transform: ranslateY(-6px);
					box-shadow: $shadow-depth-5;
				}
			}
		}
	}
	.section-footer {
		margin-top: 40px;
		text-align: center;
	}
	.section-image {
		width: 100%; height: 100%;
		position: absolute;
		left: 0; top: 0;
		z-index: 0!important;
		opacity: .75;
		background-size: cover;
		background-repeat: no-repeat;
		background-position: center center;
	}
	&.small-padding {
        padding-top: 60px;
		padding-bottom: 60px;
    }
    &.medium-padding {
		padding-top: 80px;
		padding-bottom: 80px;
    }
    &.large-padding {
		padding-top: 120px;
		padding-bottom: 120px;
    }
    &.xlarge-padding {
		padding-top: 140px;
		padding-bottom: 140px;
    }
	&.no-padding {
		padding-top: 0;
		padding-bottom: 0;
    }
	&.no-top-padding {
		padding-top: 0;
	}
	&.no-bot-padding {
		padding-bottom: 0;
	}
	.content-align-left {
		text-align: left;
	}
	.content-align-center {
		text-align: center;
	}
	.content-align-right {
		text-align: right;
	    @media screen and (max-width: $responsive-small) { text-align: left; }
	}
	.content-light,
	&.content-light {
		h1, h2, h3, h4, h5, h6, p a, p, ul, label, .required label:after {
			color: $color-white;
			strong, a {
				color: $color-white;
			}
		}
		p a {
			border-bottom: solid 1px rgba($color-white, .25);
			&:hover {
				border-bottom: solid 1px rgba($color-white, 1);
			}
		}
		.button.ghost,
		button.ghost,
		input[type='submit'].ghost,
		input[type="button"].ghost,
		.ghost input[type='submit'],
		.ghost input[type="button"]  {
			color: $color-white;
			border: solid 1px $color-white;
			background-color: transparent;
			&:hover, &:focus {
				color: $color-white;
				border: solid 1px $color-black;
				background-color: $color-black;
			}
		}
	}
	.content-dark,
	&.content-dark {
		h1, h2, h3, h4, h5, h6, p, ul, label {
			color: $color-black;
			strong {
				color: $color-black;
			}
		}
		p a {
			border-bottom: solid 1px rgba($color-black, .25);
			&:hover {
				border-bottom: solid 1px rgba($color-black, 1);
			}
		}
		.button.ghost,
		button.ghost,
		input[type='submit'].ghost,
		input[type="button"].ghost,
		.ghost input[type='submit'],
		.ghost input[type="button"]  {
			color: $color-black;
			border: solid 1px $color-primary;
			background-color: transparent;
			&:hover, &:focus {
				color: $color-white;
				border: solid 1px $color-primary;
				background-color: $color-primary;
			}
		}
	}
}

/* Bottom Section
-----------------------------------------------------------------------*/

/* CTA Section */

.cta-section {
	padding: 25px 0;
	background-color: $color-primary;
    @media screen and (max-width: $responsive-medium) {
		padding: 30px 0;
		text-align: center;
	}
	h2, p {
		color: $color-white;
	}
	h2 {
		font-size: 1.75rem;
		margin-bottom: 10px;
	}
	p {
		margin-bottom: 0;
    	@media screen and (max-width: $responsive-medium) { margin-bottom: 20px; }
	}
	.button.white {
		&:hover {
			color: $color-dark-brown !important;
			border: solid 1px $color-white;
			background-color: $color-white;
		}
	}
}

/* Extra
-----------------------------------------------------------------------*/

/* Search Modal */

.search-modal {
	width: 760px;
	max-width: 100%;
	padding: 80px;
	overflow: visible;
	outline: none;
	text-align: center;
	opacity: 0;
	background-color: $color-light-tan;
	box-shadow: $shadow-depth-5;
	@media screen and (max-width: $responsive-medium) { padding: 80px 40px; }
	@media screen and (max-width: $responsive-small) { padding: 40px 20px; }
	h2 {
		margin-bottom: 40px;
		font-size: 2.25rem;
	}
	form {
		position: relative;
		input[type='search'] {
			height: 50px;
			font-size: .8rem;
			border-color: $color-white;
			background-color: $color-white;
			@media screen and (max-width: $responsive-medium) { width: 100%; }
			@media screen and (min-width: 400px) { font-size: 1.125rem; }
		}
		button {
			width: 46px; height: 46px;
			padding: 0;
			position: absolute;
			top: 2px; right: 2px;
			line-height: 0;
			&:hover {
				box-shadow: none;
			}
			@media screen and (max-width: $responsive-small) {
				position: relative;
				width: 100%;
			}
			i {
				font-size: 1rem;
			}
		}
	}
}

/* Modal Windows */

.fancybox__container {
	z-index: 99999;
}

.fancybox__backdrop {
	background: $color-white;
	opacity: 0.95;
}

.fancybox-is-open .fancybox__backdrop {
	opacity: .95;
}

.fancybox-slide--video .fancybox-content {
	width: 100%;
    max-width: 1280px;
    max-height: 720px;
	background-color: #000;
	box-shadow: $shadow-depth-5;
}

.fancybox-loading {
	width: 20px !important;
	height: 20px !important;
	border: 4px solid rgba($color-primary, 1);
	border-top: 4px solid rgba(255, 255, 255, 0.6);
	border-radius: 100%;
	-webkit-animation: fancybox-rotate .8s infinite linear;
	animation: fancybox-rotate .8s infinite linear;
	background: transparent;
	position: absolute;
	top: 50%;
	left: 50%;
	margin-top: -25px;
	margin-left: -25px;
	z-index: 99999;
}

.f-button {
	width: 40px; height: 40px;
	display: inline-block;
	position: relative;
	top: 20px!important; right: 20px!important;
	border: 0;
	text-align: center;
	background: $color-primary;
	color: $color-white;
	cursor: pointer;
	vertical-align: top;
	outline: none;
	border-radius: 200px;
	@include transition($transitions-primary);
	&:hover {
		border: none!important;
		background: $color-primary;
		&:before {
			color: $color-white;
		}
	}
	&:before {
		position: relative;
		color: $color-white;
		font-family: 'Nucleo';
		font-size: 18px;
		display: inline-block;
		@include transition($transitions-primary);
	}
	svg {
		display: none;
	}
	&.is-close-btn:before {
		left: 1px; top: 1px;
		content: "\ea74";
	}
	&.fancybox-button--thumbs:before {
         content: "\eaa0";
         font-size: 1rem;
     }
}

.fancybox-navigation {
	.fancybox-button {
		width: 40px; height: 40px;
		padding: 0; margin: 0;
		background: $color-primary;
		transition: all 400ms cubic-bezier(0.645, 0.045, 0.355, 1)!important;
		@media screen and (max-width: $responsive-small) {
			display: none;
		}
		&:hover,
		&:focus {
			border: none!important;
			background-color: darken($color-primary, 10%)!important;
		}
		&:before {
			width: 40px!important; height: 40px!important;
			position: relative!important;
			top: 0!important; right: 0!important; left: 0!important;
			line-height: 40px;
			font-size: 20px;
			background: transparent!important;
		}
		&.fancybox-button--arrow_left {
			left: 0!important;
			&:before {
				content: "\ed74";
			}
		}
		&.fancybox-button--arrow_right {
			right: 0!important;
			&:before {
				content: "\ed75";
			}
		}
		&.disabled {
			cursor: default;
			display: none!important;
		}
	}
}

.fancybox-caption-wrap {
	background: linear-gradient(180deg, transparent 0, rgba($color-medium-grey, .1) 20%, rgba($color-medium-grey, .2) 40%, rgba($color-medium-grey, .6) 80%, rgba($color-medium-grey, .8));
	.fancybox-caption {
		border-top: 1px solid rgba($color-black, .1);
		color: $color-black;
		font-size: 16px;
	}
}

/* Carousel
-----------------------------------------------------------------------*/

.flickity-viewport {
	overflow: visible;
}

.flickity-page-dots {
	bottom: -30px;
	display: flex;
	justify-content: flex-start;
	align-items: center;
	cursor: default;
	-webkit-touch-callout: none;
	-webkit-user-select: none;
	-khtml-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	overflow: visible;
	.dot {
		position: relative;
		display: flex;
		width: 10px; height: 10px;
		padding: 0; margin: 0 16px 0 0;
		-webkit-transform: scale(1);
		-moz-transform: scale(1);
		transform: scale(1);
		opacity: .5;
		-webkit-backface-visibility: hidden;
		background-color: $color-medium-grey;
		font-size: 0;
    color: transparent;
		@include transition($transitions-primary);
		&.is-selected {
			opacity: 1;
			-webkit-transform: scale(1.3);
			-moz-transform: scale(1.3);
			transform: scale(1.3);
			background-color: $color-primary;
		}
	}
}

/* Back to Top */

.back-top {
	width: 24px; height: 40px;
	padding: 0; margin: 0;
	position: fixed;
	bottom: 15px; right: 15px;
	color: $color-white;
	text-align: center;
	line-height: 30px;
	z-index: 999;
	border-radius: 200px;
	display: none;
	transition: $transitions-primary;
	background-color: $color-primary;
	@media screen and (max-width: $responsive-small) {
		display: none !important;
	}
	&:hover {
		color: $color-white;
		box-shadow: $shadow-depth-4;
	}
	i {
		font-size: 10px;
	}
}

/* Responsive Classes */

@media screen and (max-width: $responsive-small) {
    .reverse-row .row {
		display: flex;
		flex-direction: column-reverse;
		.columns {
			width: 100%;
		}
    }
}
