/* Groups */

.group-intro {
    background-color: #ffffff;
    padding: 75px 0px;
}

.group-filters {
    label {
        font-size: 20px;
        line-height: 35px;
        color: #444444;
    }
    .loader {
        display: flex;
        position: relative;
        width: 100%;
        justify-content: center;
        align-items: center;
    }

    .group-filters-selects {
        padding-right: 26px;
        @media screen and (max-width: $responsive-medium) {
            padding-right: 0px;
        }
    }
}


.detail-card {
    position: relative;
    background-color: #ffffff;
    border-radius: 5px;
    // padding: 26px 32px;
    // padding-right: 45px;
    margin-bottom: 15px;
    
    &:after {
        content: '';
        background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTMiIGhlaWdodD0iMTMiIHZpZXdCb3g9IjAgMCAxMyAxMyIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTAuOTk5OTYyIDYuNjA2NTlMNi42MDY1NyAwLjk5OTk4NUwxMi4yMTMyIDYuNjA2NTkiIHN0cm9rZT0iIzQ0NDQ0NCIvPgo8L3N2Zz4K");
        background-repeat: no-repeat;
        position: absolute;
        top: 38px;
        right: 32px;
        width: 13px;
        height: 10px;
        transform: rotate(180deg);
        transition: transform 86ms ease-in-out;
    }

    .detail-card-heading {
        position: relative;
        padding-top: 26px;
        padding-left: 32px;
        padding-right: 45px;
        padding-bottom: 26px;
        font-size: 25px;
        line-height: 35px;
        color: #6E872D;
        margin-bottom: 0px;
        cursor: pointer;
        z-index: 2;
    }


    .detail-card-body {
        display: none;
        padding: 0px 32px;
        .detail-card-category {
            line-height: 30px;
            margin-bottom: 0px;
        }

        p {
            font-size: 16px;
            line-height: 26px;
            color: #444444;

            strong {
                font-size: 13px;
                text-transform: uppercase;
                font-weight: 600;
                letter-spacing: 0.08em;
            }
        }

        .detail-card-description {
            margin-top: 18px;
        }
    }

    .detail-card-contact {
        display: none;
        margin-top: 16px;

        h2 {
            font-weight: 500;
            font-size: 22px;
            line-height: 35px;
            color: #444444;
            
            margin-bottom: 10px;
        }
    }

    .detail-card-contact.open {
        display: block;
    }



    /* @States */

    &.selected {
        padding-bottom: 45px;
        &:after {
            transform: rotate(0deg);
            transition: transform 86ms ease-in-out;
        }
        .detail-card-body {
            display: block;
        }
    }
}

[data-group-list],
[data-group-filtered-list] {
    padding-top: 10px;
}

[data-group-filtered-list]:not(:empty) + [data-group-list] {
    display: none;
}

[data-group-filtered-list]:empty {
    display: none;
}

[data-group-filtered-list]:empty + [data-group-list] {
    display: block;
}

[data-clear-filters] {
    cursor: pointer;
}

[data-card-contact-show] {
    cursor: pointer;
    margin-top: 16px;
}