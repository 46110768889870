button.prayer-form-toggle {
  text-transform: uppercase;

  & + .msg-box {
    padding: 5px;

    &.success {
      color: darkgreen;
    }
  }
}

#prayer-form {
  display: none;
  margin-top: 20px;
}

.prayer-list {
  margin-top: 30px;

  article {
    padding: 15px 10px;

    &:nth-child(even) {
      background-color: rgba($color-white, .3);
    }
  }

  .prayer-num {
    font-style: normal;
  }

  span.hide {
    display: none;
  }

  button {
    display: inline-block;
    margin-left: 15px;
    text-transform: uppercase;
  }

  .prayer-date {
    margin-top: 10px;
    opacity: .3;
  }
}