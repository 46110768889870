/* Footer */

#footer {
	-webkit-box-shadow: inset 0px 1px 10px 1px rgba(0,0,0, .15);
	box-shadow: inset 0px 1px 10px 1px rgba(0,0,0, .15);
	background-color: $color-dark-brown;
	@media screen and (max-width: $responsive-medium) { text-align: center; }
	h5, h5 a {
		color: $color-white;
		font-size: 1rem;
		font-family: $font-primary;
		font-weight: $font-primary-semibold;
        text-transform: uppercase;
        letter-spacing: .08rem;
	}
	h5 a:hover {
		color: $color-primary;
	}
	p {
		color: rgba($color-white, .75);
		font-size: 1rem;
	}
	ul {
		padding: 0; margin: 0;
		list-style: none;
	}
	a {
		color: rgba($color-white, .75);
		&:hover {
			color: rgba($color-white, 1);
		}
	}
	.footer-top {
		padding: 80px 0 60px 0;
		.columns {
    		@media screen and (max-width: $responsive-medium) {
				min-width: 50%;
				width: 50%;
				margin-bottom: 30px;
			}
			@media screen and (max-width: $responsive-small) {
				min-width: 100%;
				width: 100%;
			}
		}
		ul li {
			padding: 0 0 8px 0;
			a {
				font-size: 1rem;
			}
		}
		form {
			margin-bottom: 30px;
			position: relative;
			input {
				height: 40px;
				margin-bottom: 0;
				padding-top: 0;
				padding-bottom: 0;
			}
			button {
				width: 36px; height: 36px;
				padding: 0;
				position: absolute;
				right: 2px; top: 2px;
				text-align: center;
			}
		}
		.socialnav {
			ul li {
				display: inline-block;
				a {
					width: 32px; height: 32px;
					padding: 0; margin-right: 5px;
					line-height: 33px;
					color: $color-white;
					font-size: 0.875rem;
					i {
						font-size: 1rem;
					}
				}
				&:last-child a {
					margin-right: 0;
				}
			}
		}
	}
	.footer-bottom {
		padding: 0 0 60px 0;
		color: rgba($color-white, .4);
		text-align: center;
		.footer-logo {
			width: 40px; height: 40px;
			margin-bottom: 15px;
			display: inline-block;
		}
		.contact-info {
			width: 100%;
			margin-bottom: 15px;
			display: block;
		}
		.idd-logo {
			width: 18px; height: 18px;
			margin: 0 4px;
			display: inline-block;
			position: relative;
			top: -1px;
		}
		.footernav {
			margin: 0 0 30px 0;
			ul li {
				margin: 0 10px;
				display: inline-block;
				a {
					color: $color-white;
					font-family: $font-primary;
					font-size: 0.875rem;
					font-weight: $font-primary-medium;
					text-transform: uppercase;
					line-height: normal;
					letter-spacing: .06rem;
					&:hover {
						color: $color-primary;
					}
				}
				&.active a {
					color: $color-primary;
				}
			}
		}
		.copyright,
		.madeby {
			font-size: 0.875rem;
		}
		.copyright {
			margin-bottom: 15px;
			ul li {
				margin: 0 5px;
				display: inline-block;
			}
			a {
				font-size: 0.875rem;
			}
		}
		.madeby {
			svg {
				width: auto; height: 24px;
				fill: lighten($color-medium-grey, 15%);
				@include transition($transitions-primary);
				&:hover {
					fill: $color-primary;
				}
			}
		}
	}
}
