/* Global */

/* Layout
-----------------------------------------------------------------------*/

::-moz-selection { background-color: $color-primary; color: $color-white; }
::selection { background-color: $color-primary; color: $color-white; }

article, aside, figure, figure img, figcaption, hgroup, footer, header, nav, section, video, object {
	display: block;
	outline: none;
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
}

/* Typography
-----------------------------------------------------------------------*/

body {
	color: $color-dark-brown;
	font-family: $font-primary, Helvetica, Arial, sans-serif;
	font-size: 1rem;
	background-color: $color-light-tan;
}

h1, h2, h3, h4, h5, h6 {
	margin: 0 0 20px 0;
	color: $color-dark-brown;
	font-family: $font-primary;
	font-weight: $font-primary-bold;
	line-height: 1.4;
}

h1 {
	font-size:  2.5rem;
	color: darken($color-dark-brown, 8%);
}

h2 {
	font-size: 2rem;
	color: darken($color-dark-brown, 8%);
	@media screen and (max-width: $responsive-medium) { font-size: 1.75rem; }
	@media screen and (max-width: $responsive-small) { font-size: 1.625rem; }
	span {
		display: block;
		margin-bottom: 10px;
		font-family: $font-primary;
		font-size: 1.125rem;
		font-weight: $font-primary-medium;
		text-transform: uppercase;
		letter-spacing: .1rem;
		@media screen and (max-width: $responsive-small) { font-size: 1rem; }
	}
}

h3 {
	margin-bottom: 15px;
	color: $color-dark-grey;
	font-family: $font-secondary;
	font-size: 1.563rem;
	font-weight: $font-secondary-regular;
	@media screen and (max-width: $responsive-medium) { font-size: 1.5rem; }
	@media screen and (max-width: $responsive-small) { font-size: 1.375rem; }
	strong {
		color: $color-secondary-green;
		font-weight: $font-secondary-regular;
	}
	a {
		font-weight: $font-secondary-regular;
	}
}

h4 {
	margin-bottom: 15px;
	color: $color-dark-grey;
	font-size: 1.25rem;
	font-weight: $font-primary-semibold;
	strong {
		color: $color-secondary-green;
		font-weight: $font-primary-semibold;
	}
	a {
		font-weight: $font-primary-semibold;
	}
}

h5 {
	font-size: 1.125rem;
}

h6 {
	font-size: 1rem;
}

p {
	margin: 0 0 20px 0;
	font-family: inherit;
	color: inherit;
	line-height: 1.6;
	strong {
		color: darken($color-dark-brown, 8%);
		font-weight: $font-primary-semibold;
	}
	em {
		color: $color-dark-brown;
	}
	a {
		font-weight: $font-primary-medium;
	}
}

.picker__select--year,.picker__select--month {
	padding: 7px 20px !important;
}

fieldset {
	border: 0;
	margin: 0;
	padding: 1rem .1rem;

  &.no-header {
    div.heading {
      display: none !important;
    }
  }

  &.field.multiplechoice {
	  label {
		  display: block;

		  input {
			  display: inline-block;
			  margin-right: .5rem;
		  }
	  }
  }
}

blockquote {
	padding: 40px; margin: 20px;
	color: $color-white;
	font-family: $font-primary;
	font-size: 1.25rem;
	border-radius: 1px;
	background-color: $color-secondary;
	@media screen and (max-width: $responsive-small) {
		margin-left: 30px;
		margin-right: 30px;
	}
	&:before, &:after  {
		font-size: 3.5rem;
		line-height: 0;
		position: relative;
		top: 22px;
		opacity: .5;
	}
	&:before {
		content: open-quote;
		margin-right: 10px;
	}
	&:after {
		content: close-quote;
		margin-left: 5px;
	}
	p {
		display: inline;
	}
}

ul, ol, dl {
	font-size: inherit;
}

ul, ol {
	padding: 0; margin: 0 40px 30px 40px;
	list-style-position: outside;
}

ul {
	list-style-type: disc;
}

a {
	color: $color-primary;
	outline: none;
	text-decoration: none;
	font-weight: $font-primary-regular;
	@include transition($transitions-primary);
	&:hover {
		color: darken($color-primary, 10%);
	}
}

hr {
	margin: 30px 0;
	height: 0;
	display: block;
	border: 0;
	border-top: 1px solid rgba(0, 0, 0, 0.1);
	border-bottom: 1px solid rgba(255, 255, 255, 0.3);
}

/* Buttons
-----------------------------------------------------------------------*/

p .button,
.button,
button,
input[type='submit'],
input[type="button"] {
	padding: 12px 24px; margin: 0;
	display: inline-block;
	position: relative;
	color: $color-white;
	font-size: 0.875rem;
	font-family: $font-primary;
	font-weight: $font-primary-medium;
	text-align: center;
	border-radius: 200px;
	text-transform: uppercase;
	letter-spacing: .08rem;
	border: solid 1px $color-primary;
	background-color: $color-primary;
	transition: $transitions-primary;
	&:last-child {
		margin-bottom: 0;
	}
	&:hover {
		color: $color-white!important;
		text-decoration: none;
		box-shadow: $shadow-depth-4;
	}
	&:focus {
		outline: none;
	}
	i {
		line-height: 0;
		font-size: 12px;
		position: relative;
		top: 1px;
	}
}

.text-center { text-align: center; }

/* Button Styles */

p .button.primary,
.button.primary,
button.primary,
.form-block.primary button {
	border: solid 1px $color-primary;
	background-color: $color-primary;
}

p .button.secondary,
.button.secondary,
button.secondary,
.form-block.secondary button {
	border: solid 1px $color-secondary;
	background-color: $color-secondary;
}

p .button.secondary-green,
.button.secondary-green,
button.secondary-green {
	border: solid 1px $color-secondary-green;
	background-color: $color-secondary-green;
}

p .button.secondary-blue,
.button.secondary-blue,
button.secondary-blue {
	border: solid 1px $color-secondary-blue;
	background-color: $color-secondary-blue;
}

p .button.secondary-red,
.button.secondary-red,
button.secondary-red {
	border: solid 1px $color-secondary-red;
	background-color: $color-secondary-red;
}

p .button.white,
.button.white,
button.white {
	color: darken($color-dark-brown, 8%);
	border: solid 1px $color-white;
	background-color: $color-white;
	&:hover {
		color: $color-white;
		border: solid 1px $color-primary;
		background-color: $color-primary;
	}
}

p .button.grey,
.button.grey,
button.grey {
	border: solid 1px $color-medium-grey;
	background-color: $color-medium-grey;
}

p .button.black,
.button.black,
button.black {
	border: solid 1px $color-black;
	background-color: $color-black;
}

.button.ghost,
button.ghost,
input[type='submit'].ghost,
input[type="button"].ghost  {
	color: $color-black;
	border: solid 1px $color-primary;
	background-color: transparent;
	&:hover {
		color: $color-white;
		border: solid 1px $color-primary!important;
		background-color: $color-primary;
	}
}

/* Button Sizes */

.button.small,
button.small,
input[type='submit'].small
input[type="button"].small {
	padding: 6px 16px;
	font-size: 0.75rem;
	i {
		line-height: 0;
		font-size: 12px;
		position: relative;
		top: 1px;
	}
}

button.share {
	line-height: 1.5!important;
}

.button.large,
button.large,
input[type='submit'].large,
input[type="button"].large {
	padding: 14px 28px;
	font-size: 0.875rem;
}

.button.xlarge,
button.xlarge,
input[type='submit'].xlarge,
input[type="button"].xlarge  {
	padding: 18px 36px;
	font-size: 1.125rem;
}

.button.xxlarge,
button.xxlarge,
input[type='submit'].xxlarge,
input[type="button"].xxlarge  {
	padding: 28px 56px;
	font-size: 1.25rem;
}

/* Extra Button Styles */

.button.video-button {
	padding-left: 60px;
	background-image: url(/assets/images/play-button.svg);
	background-repeat: no-repeat;
	background-position: left 28px center;
	background-size: 24px;
}

/* Images
-----------------------------------------------------------------------*/

a img,
a svg {
	border: 0;
	outline: none;
	backface-visibility: hidden;
	-webkit-backface-visibility: hidden;
	@include transition($transitions-primary);
}

a:hover img {
	opacity: .8;
}

figure {
	display: inline;
	padding: 0; margin: 0;
}

/* Tables
-----------------------------------------------------------------------*/

table {
	width: 100%; max-width: 100%;
	margin: 0 auto 20px auto;
	text-align: left;
}

thead th {
	color: darken($color-dark-brown, 8%);
	font-size: 0.875rem;
	font-weight: $font-primary-bold;
	text-transform: uppercase;
	letter-spacing: .08rem;
}

tr {
	border-bottom: 1px solid $color-light-grey;
	margin-bottom: 5px;
	&:last-child {
		margin-bottom: 0;
		border-bottom: none;
	}
}

tr:nth-child(even) {
}

th, td {
	padding: 15px;
}

td {
	font-weight: $font-primary-medium;
}

tfoot tr {
	background: none;
}


/* Forms
-----------------------------------------------------------------------*/

form {
	padding: 0; margin: 0;
	position: relative;
	text-align: left;
	label {
		margin-bottom: 10px;
		display: inline-block;
		color: $color-black;
		font-weight: $font-primary-semibold;
	}
	p {
		margin-bottom: 0;
	}
}

.required label:after {
	margin-left: 5px;
	font-family: $font-primary;
	content: "*";
	display: inline-block;
	color: #D50000;
}

ul.errors {
	padding: 0; margin: 0;
	list-style: none;
	li {
		width: 100%;
		padding: 0; margin: 0;
		display: inline-block;
		text-align: left;
		color: #D50000;
		font-size: 13px;
		top: -10px;
		position: relative;
	}
}

.fields .errors input {
	border: 1px solid #D50000!important;
}

input[type='text'],
input[type='password'],
input[type='date'],
input[type='datetime'],
input[type='datetime-local'],
input[type='month'],
input[type='week'],
input[type='email'],
input[type='number'],
input[type='search'],
input[type='tel'],
input[type='time'],
input[type='url'],
input[type='color'],
select,
textarea {
	width: 100%;
	padding: 12px 24px; margin: 0 0 20px 0;
	outline: none;
	color: darken($color-dark-brown, 8%);
	font-family: $font-primary;
	font-size: 1.063rem;
	border: solid 1px $color-light-tan;
	background-color: $color-white;
	border-radius: 200px;
	box-sizing: border-box;
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
	@include transition($transitions-primary);
	&:hover,
	&:focus {
		border-color: $color-light-tan;
		background-color: $color-white;
		box-shadow: 0 10px 20px rgba(0,0,0,0.03), 0 20px 40px rgba(0,0,0,0.06);
	}
}

.submit input[type='submit'],
.submit button {
	padding: 12px 24px;
	font-size: 0.875rem;
}

::-webkit-input-placeholder,
:-moz-placeholder,
::-moz-placeholder,
:-ms-input-placeholder {
	color: $color-light-grey;
	font-size: 1.063rem;
}

select,
.input select {
	padding: 10px 20px;
	background-position: right 20px center;
	background-repeat: no-repeat;
	background-size: 10px;
	background-image: url(/assets/images/select-arrow.svg);
	&:focus {
		box-shadow: $shadow-depth-1;
	}
}

button:disabled {
	opacity: .5;
	border-color: $color-dark-brown!important;
	background-color: $color-dark-brown!important;
}

textarea {
	height: auto;
	padding: 20px;
	border-radius: 3px;
}
