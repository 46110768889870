/* Blocks */

.code-block {
	display: block;
}

/* Button Block */

.button-block {
	margin-bottom: 20px;
	display: inline-block;
}

/* Image Block */

.image-block {
	img {
		margin: 0 0 20px 0;
		display: inline-block;
	}
	&.align-left img {
		margin-right: 30px;
		float: left;
	}
	&.align-center img {
		margin-left: auto;
		margin-right: auto;
		display: block;
	}
	&.align-right img {
		margin-left: 30px;
		float: right;
	}
	&.align-full img {
		display: inline-block;
	}
	&.align-left img,
	&.align-right img {
		@media screen and (max-width: $responsive-medium) { float: none; margin: 0 0 20px 0; }
	}
}

/* Video/Map block */

.video-block,
.map-block {
	width: 100%;
	max-width: 100%;
	position: relative;
	padding-bottom: 56.25%;
	margin-bottom: 40px;
	height: 0;
	overflow: hidden;
	display: block;
	box-shadow: $shadow-depth-4;
	iframe,
 	object,
 	embed {
		width: 100%; height: 100%;
		position: absolute;
		top: 0; left: 0;
		border: 0;
	}
}
