/* Events */

/* Events Page (Blocks)
-----------------------------------------------------------------------*/

.events-section {
    .section-content {
        &.event-blocks {
            padding-top: 60px;
            padding-bottom: 60px;
        }
        .event-block {
            width: 100%;
            padding: 0; margin-bottom: 10px;
            position: relative;
            float: left;
            transition: $transitions-primary;
            &:hover {
                z-index: 1;
                -webkit-transform: translateY(-6px);
                -moz-transform: translateY(-6px);
                -o-transform: translateY(-6px);
                transform: ranslateY(-6px);
                background-size: auto;
                .block-content {
                    h3, h3 a {
                        color: $color-primary;
                    }
                    .button {
                        opacity: 1;
                    }
                }
                .block-image {
                    border-top-left-radius: 24px;
                    border-top-right-radius: 24px;
                    border-bottom-left-radius: 0;
                    border-bottom-right-radius: 24px;
                    background-color: $color-primary;
                    box-shadow: $shadow-depth-5;
                    img {
                        opacity: .2;
                        -webkit-transform: scale(1.1);
                        -moz-transform: scale(1.1);
                        -o-transform: scale(1.1);
                        transform: scale(1.1);
                    }
                }
            }
            &.active {
                .block-content {
                    h3, h3 a {
                        color: $color-primary;
                    }
                    .button {
                        opacity: 1;
                    }
                }
                .block-image {
                    border-top-left-radius: 24px;
                    border-top-right-radius: 24px;
                    border-bottom-left-radius: 0;
                    border-bottom-right-radius: 24px;
                    background-color: $color-primary;
                    box-shadow: $shadow-depth-5;
                    img {
                        opacity: .2;
                        -webkit-transform: scale(1.1);
                        -moz-transform: scale(1.1);
                        -o-transform: scale(1.1);
                        transform: scale(1.1);
                    }
                }
            }
            .block-content {
                width: 100%;
                padding: 20px 0;
                float: left;
                position: relative;
                z-index: 1;
                transition: $transitions-primary;
                .category {
                    margin-bottom: 5px;
                    display: block;
                    color: $color-medium-grey;
                    font-size: 0.75rem;
                    font-weight: $font-primary-semibold;
                    text-transform: uppercase;
                    letter-spacing: .06rem;
                }
                h3, h3 a {
                    margin-bottom: 5px;
                    color: $color-primary;
                    font-family: $font-primary;
                    font-size: 1.375rem;
                    font-weight: $font-primary-medium;
                    transition: $transitions-primary;
                }
                h4 {
                    margin-bottom: 10px;
                    color: $color-dark-grey;
                    font-family: $font-secondary;
                    font-size: 1.25rem;
                    font-weight: $font-secondary-regular;
                }
                .button {
                    opacity: 0;
                    i {
                        margin-left: 4px;
                    }
                }
            }
            .block-image {
                width: 100%;
                display: block;
                float: left;
                position: relative;
                z-index: 0;
                overflow: hidden;
                transition: $transitions-primary;
                -webkit-backface-visibility: hidden;
                backface-visibility: hidden;
                background-color: $color-light-tan;
                img {
                    -webkit-transition: all 1.2s cubic-bezier(0.215, 0.61, 0.355, 1);
                    transition: all 1.2s cubic-bezier(0.215, 0.61, 0.355, 1);
                    -webkit-transform: scale(1);
                    transform: scale(1);
                }
            }
        }
    }
    .section-footer {
        margin-top: 0;
    }
}

/* Events Details Page
-----------------------------------------------------------------------*/

/* Page Header */

#page.events-details {
    .page-header {
        .header-content {
            width: 100%; height: 100%;
            padding: 0; margin: 0;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            position: absolute;
    		z-index: 10;
            @media screen and (max-width: $responsive-small) {
                padding-top: 50px;
            }
            p {
                font-size: 1rem;
                font-family: $font-primary;
                font-weight: $font-primary-semibold;
                text-transform: uppercase;
                letter-spacing: .08rem;
                @media screen and (max-width: $responsive-small) {
                    font-size: 0.875rem;
                }
            }
            .button {
                width: 80px; height: 80px;
                padding: 0; margin: 0 0 10px 0;
                line-height: 95px;
                box-shadow: 0 20px 30px rgba(0,0,0,0.12), 0 30px 60px rgba(0,0,0,0.20);
        	    @media screen and (max-width: $responsive-medium) {
                    width: 60px; height: 60px;
                    line-height: 70px;
                }
                @media screen and (max-width: $responsive-small) {
                    width: 50px; height: 50px;
                    line-height: 60px;
                }
                svg {
                    width: 24px; height: 24px;
                    display: inline-block;
                    position: relative;
                    left: 2px;
                    @media screen and (max-width: $responsive-medium) {
                        width: 20px; height: 20px;
                    }
                }
                &:hover svg {
                    fill: $color-white;
                }
            }
        }
        img {
            opacity: .75;
            width: 100%; height: auto;
            max-width: 100%;
            position: relative;
            z-index: 0;
        }
    }
}

/* Page Content */

.events-details-page {
    aside {
        padding-left: 2.5rem;
	    @media screen and (max-width: $responsive-medium) { margin-top: 20px; padding-left: 0; }
    }
    .event-header {
        margin-bottom: 20px;
        text-align: left;
        h1 {
            margin-bottom: 15px;
        }
        .date {
            width: 100%;
            display: block;
            color: $color-dark-grey;
        	font-family: $font-secondary;
        	font-size: 1.375rem;
        	font-weight: $font-secondary-regular;
        }
    }
    .section-content {
        .event-details {
            padding: 40px 30px;
            position: relative;
            background-color: $color-white;
            transition: $transitions-primary;
            &:hover {
                z-index: 1;
                border-top-left-radius: 24px;
                border-top-right-radius: 24px;
                border-bottom-left-radius: 0;
                border-bottom-right-radius: 24px;
                -webkit-transform: translateY(-6px);
                -moz-transform: translateY(-6px);
                -o-transform: translateY(-6px);
                transform: ranslateY(-6px);
                box-shadow: $shadow-depth-5;
            }
            h4 {
                margin-bottom: 20px;
                color: $color-dark-brown;
                font-size: 1rem;
                font-family: $font-primary;
                font-weight: $font-primary-bold;
                text-transform: uppercase;
                letter-spacing: .08rem;
            }
            ul {
                padding: 0; margin: 0 0 40px 0;
                list-style: none;
                li {
                    margin-bottom: 10px;
                }
            }
            .button {
                width: 100%;
                font-size: 1rem;
            }
        }
        .sharenav {
            border-top: solid 1px $color-light-grey;
            margin-top: 30px;
            padding-top: 20px;
            ul {
                padding: 0; margin: 0;
                list-style: none;
                li {
                    display: inline-block;
                    a {
                        width: 32px; height: 32px;
                        font-size: 1rem;
                        text-align: center;
                        line-height: 32px;
                        display: inline-block;
                        border-radius: 200px;
                        background-color: $color-light-tan;
                        &:hover {
                            color: $color-white;
                            background-color: $color-primary;
                        }
                    }
                }
            }
        }
    }
}
