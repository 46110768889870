/* Interior */

/* Page Header
-----------------------------------------------------------------------*/

#page.interior {
    .page-header {
        .row {
            height: auto;
        }
        .header-content {
            padding: 220px 0 140px 0;
            @media screen and (max-width: $responsive-medium) { padding: 200px 0 140px 0; }
            @media screen and (max-width: $responsive-small) { padding: 160px 0 100px 0; }
            h1 {
                font-size: 3.25rem;
                @media screen and (max-width: $responsive-medium) { font-size: 2.5rem; }
                @media screen and (max-width: $responsive-small) { font-size: 2rem; }
            }
        }
        &:before {
            -webkit-box-shadow: inset 0px 60px 120px 0px rgba(0,0,0, .2);
            box-shadow: inset 0px 60px 120px 0px rgba(0,0,0, .2);
        }
    }
    &.interior-detail {
        .page-header .header-content {
            padding: 80px 0;
        }
    }
}

/* Navigations
-----------------------------------------------------------------------*/

/* Breadcrumbs */

.breadcrumbs {
    position: relative;
    z-index: 100;
    background-color: $color-white;
    ul {
        padding: 10px 0; margin: 0;
        list-style: none;
        li {
            color: $color-medium-grey;
        }
        li, a {
            display: inline-block;
            font-size: 0.75rem;
            text-transform: uppercase;
            line-height: normal;
            letter-spacing: .06rem;
            font-weight: $font-primary-medium;
        }
        li:after {
            margin: 0 3px;
            display: inline-block;
            content: "/";
        }
        li:last-child {
            color: darken($color-dark-brown, 8%);
            &:after {
                content: none;
            }
        }
    }
}

/* Page Nav */

.pagenav {
    width: 100%;
    position: relative;
    border-bottom: solid 1px $color-light-tan;
    background-color: $color-white;
    a {
        padding: 0 16px;
        color: darken($color-dark-brown, 8%);
        font-family: $font-primary;
        font-size: 1rem;
        font-weight: $font-primary-semibold;
        text-transform: uppercase;
        line-height: 60px;
        letter-spacing: .08rem;

        &:hover,
        &.active {
            color: $color-primary;
        }
    }
    .nav-scroller-btn {
        width: 32px; height: 32px;
        padding: 0; margin: 0;
        border: none;
        line-height: 32px;
        &:hover {
            background-color: $color-primary;
            box-shadow: none;
            &:before {
                color: $color-white;
            }
        }
        &:before {
            width: 150px; height: 32px;
            display: block;
            background: $color-white;
            content: "";
            position: absolute;
            top: 0;
            z-index: 100;
            @media screen and (max-width: $responsive-medium) { width: 75px; }
        }
        &:after {
            width: 32px; height: 32px;
            position: relative;
            content: "";
            font-family: Nucleo;
            font-size: 1rem;
            color: $color-white;
            display: inline-block;
            z-index: 101;
            border-radius: 200px;
            background-color: $color-primary;
        }
        &.nav-scroller-btn--left {
            left: 4px;
            &:after {
                content: "\ed74";
            }
            &:before {
                left: 0;
                background: linear-gradient(90deg, rgba(255,255,255,1) 15%, rgba(255,255,255,0) 100%);
            }
        }
        &.nav-scroller-btn--right {
            right: 4px;
            &:after {
                content: "\ed75";
            }
            &:before {
                right: 0;
                background: linear-gradient(90deg, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 85%);
            }
        }
    }
}

/* Filter Nav */

.filter-section {
    nav {
        ul {
            padding: 0; margin: 0;
            list-style: none;
            li {
                padding: 0; margin: 0;
                display: inline-block;
                @media screen and (max-width: $responsive-small) {
                    width: 50%;
                    display: block;
                    float: left;
                    text-align: center;
                }
                button {
                    padding: 10px 20px;
                    cursor: pointer;
                    color: darken($color-dark-brown, 8%);
                    font-size: 1.125rem;
                    text-transform: capitalize;
                    letter-spacing: normal;
                    border: none;
                    background-color: transparent;
                    &:hover,
                    &:focus {
                        color: $color-primary!important;
                        outline: none;
                        box-shadow: none;
                    }
                    &.active {
                        pointer-events: none;
                        color: $color-white;
                        background-color: $color-primary;
                        box-shadow: $shadow-depth-4;
                        &:hover {
                            color: $color-white!important;
                        }
                    }
                }
            }
        }
    }
    select {
        width: 100%;
        margin-bottom: 0;
        border-color: $color-white;
        background-color: $color-white;
    }
}

/* Pagination */

.pagination {
    margin-top: 40px;
    text-align: center;
    .button.page {
        padding: 6px 10px; margin: 0 5px;
        border: none;
        color: $color-primary;
        font-size: 0.875rem;
        background-color: $color-white;
        &:hover,
        &.current {
            color: $color-white;
            background-color: $color-primary;
        }
    }
    .button.prev,
    .button.next  {
        color: $color-primary;
        border-color: $color-white;
        background-color: $color-white;
        &:hover,
        &:focus {
            color: $color-white;
            border-color: $color-primary;
            background-color: $color-primary;
        }
    }
    .button.prev {
        margin-right: 10px;
    }
    .button.next {
        margin-left: 10px;
    }

    .page-button {
        padding: 0 3px;

        &.current {
            border-bottom: 1px solid $color-primary;
        }
    }
}

/* Page Sections / Content
-----------------------------------------------------------------------*/

/* Intro Section */

.intro-section {
    padding: 60px 0;
    background-color: $color-white;
}

/* Call Out Section */

.callout-section {
    padding: 60px;
    text-align: center;
    background-color: $color-black;
    h2, h3, p {
        color: $color-white;
    }
    p {
        font-size: 1.125rem;
    }
    .button {
        margin-left: 4px;
        margin-right: 4px;
    }
}

/* Call Out Section */

.cta-banner {
    padding: 40px;
    text-align: center;
    background-color: $color-secondary-green;
    h2, h3, p {
        color: $color-white;
    }
    p {
        font-size: 1rem;
    }
}

/* Two Column */

.twocol-section {
    .content-block {
        padding-left: 0.625rem;
    	padding-right: 0.625rem;
    }
}

/* Page Sidebar
-----------------------------------------------------------------------*/

.sectionnav {
    padding: 30px 0; margin-bottom: 30px;
    position: relative;
    background-color: $color-white;
    transition: $transitions-primary;
    border-radius: 3px;
    @media screen and (max-width: $responsive-medium) { margin-top: 30px; }
    &:hover {
        z-index: 1;
        border-top-left-radius: 24px;
        border-top-right-radius: 24px;
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 24px;
        -webkit-transform: translateY(-6px);
        -moz-transform: translateY(-6px);
        -o-transform: translateY(-6px);
        transform: ranslateY(-6px);
        box-shadow: $shadow-depth-5;
    }
    h4, h4 a {
        padding: 0 30px; margin-bottom: 20px;
        color: $color-dark-brown;
        font-size: 1rem;
        font-family: $font-primary;
        font-weight: $font-primary-bold;
        text-transform: uppercase;
        letter-spacing: .08rem;
        a {
            padding: 0; margin-bottom: 0;
            color: darken($color-dark-brown, 8%);
            &:hover {
                color: $color-primary;
            }
        }
    }
    ul {
        padding: 0; margin: 0;
        list-style: none;
        li {
            width: 100%;
            padding: 0 10px; margin: 0 0 5px 0;
            display: block;
            line-height: normal;
            &:last-child {
                margin-bottom: 0;
            }
            a {
                width: 100%;
                display: block;
                padding: 10px 20px; margin: 0;
                color: darken($color-dark-brown, 8%);
                font-size: 1rem;
                text-transform: none;
                letter-spacing: normal;
                line-height: normal;
                border-radius: 200px;
                background-color: rgba($color-white, 0);
                &:hover {
                    color: $color-primary;
                }
            }
            ul.subnav {
                display: none;
                padding: 10px 0 0 0; margin: 0;
                li {
                    padding: 0;
                    display: inline-block;
                }
                li a {
                    color: $color-primary;
                    background-color: rgba($color-white, 0);
                    &:hover {
                        color: darken($color-primary, 10%);
                    }
                }
                li.active a {
                    color: $color-primary;
                    background-color: rgba($color-white, 0);
                    &:before {
                        margin-right: 5px;
                        display: inline-block;
                        content: "\edb6";
                        font-family: 'Nucleo';
                        font-size: 12px;
                        cursor: pointer;
                        color: inherit;
                    }
                }
            }
            &.active  {
                a {
                    color: $color-white;
                    background-color: $color-primary;
                }
                ul.subnav {
                    display: block;
                }
            }
        }
    }
}

.sidebar {
    padding-left: 2.5rem;
    .sidebar-block {
        padding: 40px 30px 20px 30px; margin-bottom: 30px;
        position: relative;
        background-color: $color-white;
        transition: $transitions-primary;
        border-radius: 3px;
        &:hover {
            z-index: 1;
            border-top-left-radius: 24px;
            border-top-right-radius: 24px;
            border-bottom-left-radius: 0;
            border-bottom-right-radius: 24px;
            -webkit-transform: translateY(-6px);
            -moz-transform: translateY(-6px);
            -o-transform: translateY(-6px);
            transform: ranslateY(-6px);
            box-shadow: $shadow-depth-5;
        }
        &:last-of-type {
            margin-bottom: 0;
        }
    }
}

/* Extra Pages
-----------------------------------------------------------------------*/

/* Site Map */

.sitemap-section {
    h2 {
        font-size: 1.75rem;
    }
    ul {
        padding: 0; margin: 0;
        list-style: none;
        li {
            margin-bottom: 10px;
            a {
                font-size: 1.125rem;
                @media screen and (max-width: $responsive-medium) { font-size: 1rem; }
            }
            ul {
                margin-top: 10px;
                margin-left: 30px;
            }
        }
    }
}

/* Page Not Found */

#page.page-not-found {
    .page-header {
        background: $color-primary;
        .header-content {
            padding: 200px 0;
            h1, h2, a {
                color: $color-white;
            }
            h1 {
                margin: 0;
                font-size: 7.75rem;
                font-weight: $font-primary-bold;
            }
            h2 {
                margin: 0 0 30px 0;
            }
            p {
                font-size: 1.125rem;
            }
            a {
                text-decoration: underline;
                &:hover {
                    text-decoration: none;
                }
            }
        }
    }
}

/* Search Results */

#page.search-results {
    .search-section {
        ul {
            padding: 0; margin: 0;
            list-style: none;
            li {
                padding-bottom: 10px; margin-bottom: 10px;
                border-bottom: solid 1px darken($color-light-grey, 8%);
                &:last-child {
                    border-bottom: none;
                }
                ul {
                    padding-top: 10px; margin-top: 10px;
                    border-top: solid 1px darken($color-light-grey, 8%);
                }
                ul li {
                    border-bottom: none;
                    padding-bottom: 0; margin-bottom: 0;
                }
            }
        }
    }
}
