/* Header
-----------------------------------------------------------------------*/

.header {
    width: 100%; height: auto;
    position: fixed;
    top: 0; left: 0; right: 0;
    z-index: 9999;
    will-change: all;
    transition: all 0.25s ease;
    display: flex;
    flex-direction: column;
    .row {
        max-width: 100%;
        position: relative;
    }
    .header-content {
        width: 100%; height: auto;
        padding: 5px 20px;
        position: fixed;
        top: 0; left: 0; right: 0;
        z-index: 9999;
        will-change: all;
        background-color: rgba($color-white, 0);
        transition: all 200ms cubic-bezier(0.000, 0.000, 0.580, 1.000);
        @media screen and (max-width: 1278px) { padding: 20px 10px;}
        .columns {
            display: flex;
            align-items: center;
        }
        .header-right {
            justify-content: flex-end;
        }
        .brand {
            img {
                width: auto; max-width: none; height: 42px;
                @media screen and (max-width: 1370px) { height: 36px; }
                &.light-logo {
                    display: inline-block;
                }
                &.color-logo {
                    display: none;
                }
            }
        }
        .mainnav,
        .utilitynav  {
            ul {
                padding: 0; margin: 0;
                list-style: none;
                li, a {
                    display: inline-block;
                }
                li a {
                    color: $color-white;
                    font-family: $font-primary;
                    font-size: 0.9375rem;
                    font-weight: $font-primary-semibold;
                    text-transform: uppercase;
                    line-height: normal;
                    letter-spacing: .08rem;
                    &:hover {
                        color: $color-primary;
                    }
                }
                li.active a {
                    color: $color-primary;
                }
            }
        }
        .utilitynav {
            text-align: right;
            @media screen and (max-width: 1278px) { display: none; }
            ul li {
                margin-left: 20px;
                @media screen and (max-width: 1400px) { margin-right: 10px; }
                &.search-icon i {
                    font-size: 0.9375rem;
                }
            }
        }
        .mainnav {
            padding-left: 40px;
            text-align: left;
            @media screen and (max-width: 1400px) { padding-left: 30px; }
            @media screen and (max-width: 1278px) { display: none; }
            .main-menu {
                float: left;
                li {
                    margin-right: 20px;
                    line-height: 90px;
                    transition: line-height 200ms cubic-bezier(0.000, 0.000, 0.580, 1.000);
                    @media screen and (max-width: 1400px) { margin-right: 10px; }
                }

                li.has-children:hover {
                    position: relative;
                }

                a.is-dropdown {
                    &:after {
                        margin-left: 4px;
                        display: inline-block;
                        content: "\ed1a";
                        font-family: 'Nucleo';
                        font-size: 10px;
                        font-weight: 600;
                        position: relative;
                    }
                }

                a.is-subdropdown {
                    display: flex!important;
                    flex-direction: row;
                    align-items: center;
                    justify-content: space-between;
                }

                a.is-subdropdown .arrow {
                
                    &:after {
                        margin-left: 4px;
                        display: inline-block;
                        content: "\ed1a";
                        font-family: 'Nucleo';
                        font-size: 10px;
                        font-weight: 600;
                        position: relative;
                        transform: rotate(-90deg);
                    }
                }

                li.has-children:hover {
                    .sub-menu {
                        display: block;
                    }
                }
            }
            .main-menu .sub-menu {
                padding-bottom: 4px;
                min-width: 12em; /* allow long menu items to determine submenu width */
                *width: 12em; /* no auto sub width for IE7, see white-space comment below */
                background-color: $color-white;
                box-shadow: $shadow-depth-4;
                border-radius: 3px;
                display: none;
                position: absolute;

                li.has-sub-children:hover {
                    .sub-menu-child {
                        display: block;
                    }
                }

                li {
                    width: 100%;
                    padding: 2px 10px; margin: 0;
                    line-height: normal;
                    display: block;
                    float: left;
                    background-color: $color-white;
                    white-space: nowrap; /* no need for Supersubs plugin */
                    *white-space: normal; /* ...unless you support IE7 (let it wrap) */
                    &:first-child {
                        padding-top: 20px;
                    }
                    &:last-child {
                        padding-bottom: 20px;
                    }
                    a {
                        width: 100%;
                        display: block;
                        padding: 6px 16px; margin: 0;
                        color: darken($color-dark-brown, 8%)!important;
                        font-size: 1rem;
                        font-weight: $font-primary-medium;
                        text-transform: none;
                        letter-spacing: normal;
                        line-height: normal;
                        border-radius: 200px;
                        background-color: rgba($color-white, 0);
                        transition: background-color .2s ease, color 0s ease;
                        zoom: 1; /* IE7 */
                        &.active,
                        &:hover {
                            color: $color-white!important;
                            background-color: $color-primary;
                        }
                    }
                }
            }

            .main-menu .sub-menu-child {
                display: none;
                position: absolute;
                top: 0;
                transform: translateX(100%);
            }
            .main-menu li.sfHover a {
                color: $color-primary;
            }
            .sf-arrows .sf-with-ul:after {
                margin-left: 4px;
                display: inline-block;
                content: "\ed1a";
                font-family: 'Nucleo';
                font-size: 10px;
                font-weight: 600;
                position: relative;
            }
            .sf-arrows > li > .sf-with-ul:focus:after,
            .sf-arrows > li:hover > .sf-with-ul:after,
            .sf-arrows > .sfHover > .sf-with-ul:after {
                border-top-color: white; /* IE8 fallback colour */
            }
            .sf-arrows ul .sf-with-ul:after {  /* styling for right-facing arrows */
                margin-top: -5px;
                margin-right: -3px;
                content: "\ed1c";
                position: absolute;
                top: 50%; right: 16px;
            }
            .sf-arrows ul li > .sf-with-ul:focus:after,
            .sf-arrows ul li:hover > .sf-with-ul:after,
            .sf-arrows ul .sfHover > .sf-with-ul:after {
                border-left-color: white;
            }
        }
        &.headroom--pinned {
            background-color: rgba($color-white, 1);
        }
        &.headroom--unpinned {
        }
        &.headroom--top {
            background-color: rgba($color-white, 0);;
        }
        &.headroom--not-top {
            background-color: rgba($color-white, 1);
            box-shadow: $shadow-depth-2;
            .brand {
                img.light-logo {
                    display: none;
                }
                img.color-logo {
                    display: inline-block;
                }
            }
            .mainnav ul li,
            .utilitynav ul li  {
                line-height: 60px;
                a {
                    color: darken($color-dark-brown, 8%);
                }
                a:hover,
                &.active a,
                &.active-hover > a {
                    color: $color-primary;
                }
                a.button  {
                    color: $color-white!important;
                }
            }
        }
    }
}

/* Mobile Nav */

.mobilenav {
    display: none;
    @media screen and (max-width: 1278px) { display: block; }
    .search-icon,
    .mobile-icon {
        width: 40px; height: 40px;
        padding: 0;
        border-radius: 200px;
        overflow: hidden;
        color: $color-white;
        line-height: 0;
        border: none;
        border-color: $color-primary;
        background-color: $color-primary;
        transition: all 0.25s ease;
        outline: none;
    }
    .search-icon {
        margin-right: 10px;
        text-align: center;
        i {
            position: relative;
            left: 0; top: 2px;
            line-height: 0;
            font-size: 18px;
        }
    }
    .mobile-icon {
        .hamburger-inner,
        .hamburger-inner:before,
        .hamburger-inner:after {
            background-color: $color-white;
        }
        .hamburger-box {
            height: 14px;
        }
        &:before,
        &:after {
            content: none;
        }
    }
}

.canvasnav {
    height: 100%;
    padding: 10px 0;
    z-index: 9999;
    background-color: $color-primary;
    .mobilenav ul {
        width: 100%;
        padding: 0; margin: 0;
        list-style: none;
        float: left;
        box-sizing: padding-box;
        text-align: left;
        background-color: $color-primary;
        li, a {
            width: 100%;
            float: left;
            display: block;
        }
        li {
            padding: 0; margin: 0;
            position: relative;
            a {
                position: relative;
                padding: 15px 20px;
                color: $color-white;
                font-family: $font-primary;
                font-size: 1rem;
                font-weight: $font-primary-semibold;
                text-transform: uppercase;
                line-height: normal;
                letter-spacing: .08rem;
                &:hover,
                &:focus,
                &:active {
                    color: $color-white;
                    &:before {
                        width: 20px;
                        opacity: 1;
                    }
                }
            }
            &.search-icon {
                display: none;
            }
            &.button.small {
                padding: 0 20px;
                box-shadow: none !important;
                a {
                    padding: 10px;
                    margin-top: 10px;
                    color: darken($color-dark-brown, 8%);
                    font-size: 14px;
                    border-radius: 200px;
                    border-color: $color-white;
                    background-color: $color-white;
                    &:hover {
                        box-shadow: $shadow-depth-4;
                    }
                }
            }
            ul {
                padding: 0 20px;
                display: none;
                background-color: $color-white;
                li {
                    min-height: auto;
                    line-height: normal;
                    &:first-child {
                        padding-top: 15px;
                    }
                    &:last-child {
                        padding-bottom: 15px;
                    }
                    a {
                        width: 100%;
                        display: block;
                        margin: 0; padding: 10px 0;
                        color: darken($color-dark-brown, 8%)!important;
                        font-size: 1rem;
                        font-weight: $font-primary-medium;
                        text-transform: none;
                        letter-spacing: normal;
                        line-height: normal;
                        &:hover {
                            color: $color-primary;
                        }
                    }
                }
            }
            .drop-button {
                width: 30px; height: 30px;
                padding: 0; margin: 0;
                position: absolute;
                right: 10px; top: 10px;
                border: 0;
                color: $color-white!important;
                font-size: 1.125rem;
                text-align: center;
                letter-spacing: 0px;
                outline: 0;
                box-shadow: none;
                background-color: $color-primary;
                i {
                    padding: 0; margin: 0;
                    position: relative;
                    display: block;
                    transform-origin: 50%;
                    transition: all 200ms ease;
                }
                &.open {
                    color: $color-primary!important;
                    background-color: $color-white;
                    i {
                        transform: rotate(90deg);
                    }
                }
            }
            ul {
                .drop-button {
                    display: none!important;
                }
            }
        }
    }
}

/* Canvas Menu */

[off-canvas*=push] {
    z-index: 9999;
}

[off-canvas*=right] {
    width: 240px;
}

#overlay {
    width: 100%; height: 100%;
    position: absolute;
    top: 0; left: 0; right: 0;bottom: 0;
    visibility: hidden;
    opacity: 0;
    background-color: rgba($color-black, 0.50);
    z-index: 9998;
    @include transition($transitions-primary);
}

.js-close-any #overlay {
    visibility: visible;
    opacity: 1;
}

body.mobile-nav-open {
    overflow: hidden;
}

.mobilenav {
    &.is-active {
        z-index: 99999;
        position: relative;
    }
}

.mobile-nav-overlay {

        position: fixed;
        top: 0; left: 0; right: 0; bottom: 0;
        z-index: 9999;
        background-color: white;
        transition: all 0.25s ease;
        width: 100vw;
        height: 100vh;
    

    .mobile-nav-container {
        padding: 80px 24px 40px;

        overflow-y: scroll;
        height: 100%;

        ul {
            list-style: none;
            padding: 0;
            margin: 0;

        }

        .main-menu {
            display: flex;
            flex-direction: column;
            gap: 24px;

            .mobile-link {
                display: flex;
                flex-direction: row;
                align-items: center;

            }

            .mobile-link a {
                font-family: "proxima-nova", sans-serif;
                font-size: 1.25rem;
                font-weight: 600;
                text-transform: uppercase;
                line-height: normal;
                letter-spacing: 0.08rem;
                color: #3d3e33 !important;

                &:hover {
                    color: #DB8D1F !important;
                }
            }

            .mobile-link:has(.arrow) {
                display: flex;
                flex-direction: row;
            }

            .mobile-link .arrow {
                cursor: pointer;
                &:after {
                    margin-left: 4px;
                    display: inline-block;
                    content: "\ed1a";
                    font-family: 'Nucleo';
                    font-size: 12px;
                    font-weight: 600;
                    position: relative;
                }
            }

            .sub-menu-child,
            .sub-menu {
                li a {
                   
                    display: block;
                    padding: 6px 16px;
                    margin: 0;
                    color: #3d3e33 !important;
                    font-size: 1rem;
                    font-weight: 500;
                    text-transform: none;
                    letter-spacing: normal;
                    line-height: normal;
                    border-radius: 200px;
                    background-color: rgba(255, 255, 255, 0);
                    transition: color 0.2s ease, color 0s ease;
                    zoom: 1; 


                    &:hover {
                        color: #DB8D1F !important;
                    }
                }
            }

            .sub-menu-child {
                margin-left: 20px;
            }

            .sub-menu {
                margin-top: 16px;
            }
        }
    }
}
