/* Home */

/* Page Header
-----------------------------------------------------------------------*/

/* h1 Page title */

.pagetitle {
    position: absolute;
    overflow: hidden;
    width: 1px; height: 1px;
    padding: 0;
    border: 0;
}

#page.home .page-header {
    height: 100vh;
    .header-content {
        h1 {
            font-size: 3.25rem;
		    @media screen and (max-width: $responsive-small) { font-size: 2rem; }
        }
        p {
            @media screen and (max-width: $responsive-medium) {
                margin-bottom: 30px;
            }
        }
        .button {
            margin-left: 4px;
            margin-right: 4px;
            @media screen and (max-width: $responsive-small) {
                margin: 0;
            }
        }
        .button.ghost {
            color: $color-white;
            border: solid 1px $color-white;
        }
        .button-block {
            @media screen and (max-width: $responsive-small) {
                margin: 0 0 20px 0;
                display: block;
            }
        }
    }
}

/* Page Sections
-----------------------------------------------------------------------*/

/* Welcome */

.welcome-section  {
    overflow: hidden;
    padding: 40px 0;
    z-index: 12;
    .welcome-text {
        .content-align-right {
    	    @media screen and (max-width: $responsive-small) { text-align: center; }
    	}
        .button {
            margin-left: 10px;
            @media screen and (max-width: $responsive-small) { margin-left: 0; }
        }
    }
    .welcome-image {
        text-align: center;
    }
    .section-image {
        height: 100vh;
        opacity: .2;
        background-size: contain;
        background-position: center top;
        background-image: url(/assets/images/top-pattern.svg);
    }
}

/* Featured Sermon */

#page.home .page-section.sermons-section {
    position: relative;
    z-index: 100!important;
    background-color: #111;
    .columns {
        display: block;
    }
    .sermon-content {
        text-align: center;
        h2, h3, p {
            color: $color-white;
        }
        h2 {
            margin-bottom: 0;
            font-size: 3.25rem;
            text-transform: uppercase;
            letter-spacing: .06rem;
            @media screen and (max-width: $responsive-medium) { font-size:2.5rem; }
            @media screen and (max-width: $responsive-small) { font-size: 2rem; }
            strong {
                display: block;
                margin-bottom: 10px;
                font-family: $font-primary;
                font-size: 1.125rem;
                font-weight: $font-primary-medium;
                text-transform: uppercase;
                letter-spacing: .1rem;
                @media screen and (max-width: $responsive-small) { font-size: 1rem; }
            }
        }
        h3 {
            margin-bottom: 30px;
            margin-top: 10px;
            color: $color-white;
        }
        .button {
            margin-left: 4px;
            margin-right: 4px;
        }
    }
    .section-image {
        opacity: .5;
        background-position: center center;
    }
}

.vidbg-container {
    position: absolute; 
    z-index: 1; 
    inset: 0px; 
    overflow: hidden; 
    width: 100%;
    height: 100%;

    >video {
        user-select: none;
        pointer-events: none;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}

/* Recent Events */

#page.home .page-section.events-section {
    padding-bottom: 80px;
    .section-header {
        text-align: left;
        @media screen and (max-width: $responsive-small) { text-align: center; }
    }
    .section-content {
        position: relative;
        padding-left: 10px;
        .carousel {
            width: 100%; height: 100%;
            position: absolute;
            left: 0;
            outline: none;
            position: relative;
            .flickity-slider {
                display: flex;
            }
        }
        .carousel-cell {
            width: 40%; min-height: 500px;
            margin-right: 20px;
            counter-increment: gallery-cell;
            @media screen and (max-width: $responsive-medium) { min-height: auto; }
            @media screen and (max-width: $responsive-small) { width: 80%; }
            &.is-selected {
                .block-image {
                    box-shadow: $shadow-depth-5;
                    img {
                        opacity: 1;
                        &:hover {
                            opacity: .2;
                        }
                    }
                }
            }
        }
        .flickity-button {
        	width: 40px; height: 40px;
        	padding: 0; margin: 0;
        	color: $color-white;
        	font-size: 1rem;
        	font-family: $font-primary;
        	font-weight: $font-primary-medium;
        	text-align: center;
        	line-height: 38px;
        	display: inline-block;
        	top: -100px; bottom: 0;
        	z-index: 100;
        	-webkit-transform: none;
        	transform: none;
        	border-radius: 200px;
        	text-transform: uppercase;
        	letter-spacing: .06rem;
        	border: solid 1px $color-primary;
        	background-color: $color-primary;
        	transition: $transitions-primary;
            @media screen and (max-width: $responsive-small) { display: none; }
        	&:focus,
        	&:hover {
                outline: none!important;
        		border: solid 1px $color-primary;
        		background-color: $color-primary;
        	}
        	&:disabled {
        		border: solid 1px $color-dark-brown;
        		background-color: $color-dark-brown;
        	}
        	svg {
        		display: none;
        	}
        	&:before {
        		width: 40px; height: 40px;
        		font-family: Nucleo;
        		display: block;
        	}
        	&.flickity-prev-next-button.previous {
        		right: 100px;
        	 	left: auto;
        		&:before {
        			content: "\ed74";
        		}
        	}
        	&.flickity-all-button {
        		right: 50px;
        		&:before {
        			content: "\eaa0";
        		}
        	}
        	&.flickity-prev-next-button.next {
        		right: 0;
        		&:before {
        			content: "\ed75";
        		}
        	}
        }
        .flickity-page-dots {
            @media screen and (max-width: $responsive-medium) {
                bottom: 0;
            	justify-content: center;
             }
        	.flickity-page-dot {
                @media screen and (max-width: $responsive-medium) {
        		    margin: 0 8px;
                 }
        	}
        }
    }
}

/* Connect */

.connect-section {
    padding-bottom: 0;
    .section-header {
        margin-bottom: 0;
        z-index: 3!important;
        h2 {
            font-size: 2.625rem;
            @media screen and (max-width: $responsive-medium) { font-size: 2.5rem; }
            @media screen and (max-width: $responsive-small) { font-size: 2rem; }
        }
        p {
            margin-bottom: 40px;
        }
    }
    .section-content {
        margin-top: -60px;
        max-width: 100%;
        @media screen and (max-width: $responsive-medium) { margin-top: 60px;}
        img {
            margin: 0 auto;
        }
    }
    .section-footer {
        margin-top: -60px;
    }
    .app-section {
        margin-top: -200px;
        margin-bottom: 0;
        padding-top: 300px;
        padding-bottom: 0;
        text-align: center;
        position: relative;
        z-index: 3!important;
        overflow: hidden;
        p {
            font-size: 1.125rem;
        }
        .button {
            margin-bottom: 60px;
        }
        img {
            @media screen and (max-width: $responsive-medium) { height: 240px; }
        }
    }
    .section-image {
        opacity: .5;
        background-size: contain;
        background-position: center bottom;
        background-image: url(/assets/images/bottom-pattern.svg);
    }
}
