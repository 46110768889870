/* Missons */

/* Page Sections
-----------------------------------------------------------------------*/

/* More Info */

.more-section {
    background-color: $color-light-brown;
    .section-image {
        filter: blur(6px);
        opacity: .4;
        background-color: $color-light-brown;
        background-blend-mode: overlay;
        background-image: url(/assets/images/missions/more-bg.jpg);
    }
}

/* Apply */

.apply-section {
    background-color: $color-white;
    .content-block {
        font-size: 1.125rem;
    }
}
