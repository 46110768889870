/*--------------------------------

Nucleo Web Font
Generated using nucleoapp.com

-------------------------------- */
@font-face {
  font-family: 'Nucleo';
  src:  url('/assets/fonts/Nucleo.eot?jdete2');
  src:  url('/assets/fonts/Nucleo.eot?jdete2#iefix') format('embedded-opentype'),
    url('/assets/fonts/Nucleo.ttf?jdete2') format('truetype'),
    url('/assets/fonts/Nucleo.woff?jdete2') format('woff'),
    url('/assets/fonts/Nucleo.woff2?jdete2') format('woff2'),
    url('/assets/fonts/Nucleo.svg?jdete2#Nucleo') format('svg');
  font-weight: normal;
  font-style: normal;
}

/*------------------------
	base class definition
-------------------------*/

i.nucleo {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'Nucleo' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  display: inline-block;
  /* Better Font Rendering =========== */
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/*------------------------
  change icon size
-------------------------*/
/* relative units */
.icon-sm {
  font-size: 0.8em;
}
.icon-lg {
  font-size: 1.2em;
}
/* absolute units */
.icon-16 {
  font-size: 16px;
}
.icon-32 {
  font-size: 32px;
}
/*----------------------------------
  add a square/circle background
-----------------------------------*/
.icon-bg-square,
.icon-bg-circle {
  padding: 0.35em;
  background-color: #eee;
}
.icon-bg-circle {
  border-radius: 50%;
}
/*------------------------------------
  use icons as list item markers
-------------------------------------*/
.icon-ul {
  padding-left: 0;
  list-style-type: none;
}
.icon-ul > li {
  display: flex;
  align-items: flex-start;
  line-height: 1.4;
}
.icon-ul > li > .icon {
  margin-right: 0.4em;
  line-height: inherit;
}
/*------------------------
  spinning icons
-------------------------*/
.icon-is-spinning {
  -webkit-animation: icon-spin 2s infinite linear;
  -moz-animation: icon-spin 2s infinite linear;
  animation: icon-spin 2s infinite linear;
}
@-webkit-keyframes icon-spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}
@-moz-keyframes icon-spin {
  0% {
    -moz-transform: rotate(0deg);
  }
  100% {
    -moz-transform: rotate(360deg);
  }
}
@keyframes icon-spin {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
/*------------------------
  rotated/flipped icons
-------------------------*/
.icon-rotate-90 {
  filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=1);
  -webkit-transform: rotate(90deg);
  -moz-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  -o-transform: rotate(90deg);
  transform: rotate(90deg);
}
.icon-rotate-180 {
  filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=2);
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  -o-transform: rotate(180deg);
  transform: rotate(180deg);
}
.icon-rotate-270 {
  filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=3);
  -webkit-transform: rotate(270deg);
  -moz-transform: rotate(270deg);
  -ms-transform: rotate(270deg);
  -o-transform: rotate(270deg);
  transform: rotate(270deg);
}
.icon-flip-y {
  filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=0);
  -webkit-transform: scale(-1, 1);
  -moz-transform: scale(-1, 1);
  -ms-transform: scale(-1, 1);
  -o-transform: scale(-1, 1);
  transform: scale(-1, 1);
}
.icon-flip-x {
  filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=2);
  -webkit-transform: scale(1, -1);
  -moz-transform: scale(1, -1);
  -ms-transform: scale(1, -1);
  -o-transform: scale(1, -1);
  transform: scale(1, -1);
}
/*------------------------
	icons
-------------------------*/

.icon-warning::before {
  content: "\ea02";
}

.icon-question::before {
  content: "\ea03";
}

.icon-c-warning::before {
  content: "\ea04";
}

.icon-c-question::before {
  content: "\ea05";
}

.icon-c-info::before {
  content: "\ea06";
}

.icon-info::before {
  content: "\ea07";
}

.icon-s-warning::before {
  content: "\ea08";
}

.icon-s-question::before {
  content: "\ea09";
}

.icon-s-info::before {
  content: "\ea0a";
}

.icon-t-warning::before {
  content: "\ea0b";
}

.icon-s-pulse::before {
  content: "\ea0c";
}

.icon-c-pulse::before {
  content: "\ea0d";
}

.icon-archive-drawer::before {
  content: "\ea0e";
}

.icon-attachment::before {
  content: "\ea0f";
}

.icon-attach::before {
  content: "\ea10";
}

.icon-ban::before {
  content: "\ea11";
}

.icon-battery-power::before {
  content: "\ea12";
}

.icon-battery-charging::before {
  content: "\ea13";
}

.icon-battery-status::before {
  content: "\ea14";
}

.icon-battery-low::before {
  content: "\ea15";
}

.icon-alarm::before {
  content: "\ea16";
}

.icon-buzz::before {
  content: "\ea17";
}

.icon-bell::before {
  content: "\ea18";
}

.icon-bluetooth::before {
  content: "\ea19";
}

.icon-d-add::before {
  content: "\ea1a";
}

.icon-d-delete::before {
  content: "\ea1b";
}

.icon-d-remove::before {
  content: "\ea1c";
}

.icon-bolt::before {
  content: "\ea1d";
}

.icon-book::before {
  content: "\ea1e";
}

.icon-bookmark-add-2::before {
  content: "\ea1f";
}

.icon-bookmark-delete-2::before {
  content: "\ea20";
}

.icon-bullet-list::before {
  content: "\ea21";
}

.icon-calendar::before {
  content: "\ea22";
}

.icon-calendar-2::before {
  content: "\ea23";
}

.icon-calendar-event-create::before {
  content: "\ea24";
}

.icon-calendar-event::before {
  content: "\ea25";
}

.icon-calendar-event-2::before {
  content: "\ea26";
}

.icon-calendar-date::before {
  content: "\ea27";
}

.icon-calendar-date-2::before {
  content: "\ea28";
}

.icon-zoom::before {
  content: "\ea29";
}

.icon-magnifier-zoom-in::before {
  content: "\ea2a";
}

.icon-magnifier-zoom-out::before {
  content: "\ea2b";
}

.icon-magnifier::before {
  content: "\ea2c";
}

.icon-zoom-in::before {
  content: "\ea2d";
}

.icon-zoom-out::before {
  content: "\ea2e";
}

.icon-search-zoom-in::before {
  content: "\ea2f";
}

.icon-search-zoom-out::before {
  content: "\ea30";
}

.icon-search::before {
  content: "\ea31";
}

.icon-add-notification::before {
  content: "\ea32";
}

.icon-new-notification::before {
  content: "\ea33";
}

.icon-p-chart::before {
  content: "\ea34";
}

.icon-g-chart::before {
  content: "\ea35";
}

.icon-d-chart::before {
  content: "\ea36";
}

.icon-b-chart::before {
  content: "\ea37";
}

.icon-b-comment::before {
  content: "\ea38";
}

.icon-a-chat::before {
  content: "\ea39";
}

.icon-b-chat::before {
  content: "\ea3a";
}

.icon-f-chat::before {
  content: "\ea3b";
}

.icon-r-chat::before {
  content: "\ea3c";
}

.icon-f-comment::before {
  content: "\ea3d";
}

.icon-comment::before {
  content: "\ea3e";
}

.icon-chat::before {
  content: "\ea3f";
}

.icon-o-check::before {
  content: "\ea40";
}

.icon-n-check::before {
  content: "\ea41";
}

.icon-c-check::before {
  content: "\ea42";
}

.icon-g-check::before {
  content: "\ea43";
}

.icon-check-in::before {
  content: "\ea44";
}

.icon-check-out::before {
  content: "\ea45";
}

.icon-remove::before {
  content: "\ea46";
}

.icon-table-slide::before {
  content: "\ea47";
}

.icon-table-move::before {
  content: "\ea48";
}

.icon-shopping-label::before {
  content: "\ea49";
}

.icon-target::before {
  content: "\ea4a";
}

.icon-clock::before {
  content: "\ea4b";
}

.icon-time-alarm::before {
  content: "\ea4c";
}

.icon-time-clock::before {
  content: "\ea4d";
}

.icon--stopwatch::before {
  content: "\ea4e";
}

.icon-trash-can::before {
  content: "\ea4f";
}

.icon-bin::before {
  content: "\ea50";
}

.icon-trash::before {
  content: "\ea51";
}

.icon-toggle::before {
  content: "\ea52";
}

.icon-switches::before {
  content: "\ea53";
}

.icon-widget::before {
  content: "\ea54";
}

.icon-window-add::before {
  content: "\ea55";
}

.icon-window-delete::before {
  content: "\ea56";
}

.icon-check::before {
  content: "\ea57";
}

.icon-f-check::before {
  content: "\ea58";
}

.icon-d-check::before {
  content: "\ea59";
}

.icon-i-check::before {
  content: "\ea5a";
}

.icon-s-check::before {
  content: "\ea5b";
}

.icon-c-add::before {
  content: "\ea5c";
}

.icon-f-add::before {
  content: "\ea5d";
}

.icon-f-delete::before {
  content: "\ea5e";
}

.icon-f-remove::before {
  content: "\ea5f";
}

.icon-c-delete::before {
  content: "\ea60";
}

.icon-c-remove::before {
  content: "\ea61";
}

.icon-cloud-forecast::before {
  content: "\ea62";
}

.icon-cloud::before {
  content: "\ea63";
}

.icon-speedometer::before {
  content: "\ea64";
}

.icon-f-dashboard::before {
  content: "\ea65";
}

.icon-h-dashboard::before {
  content: "\ea66";
}

.icon-dashboard::before {
  content: "\ea67";
}

.icon-database::before {
  content: "\ea68";
}

.icon-floppy-disk::before {
  content: "\ea69";
}

.icon-dock-bottom::before {
  content: "\ea6a";
}

.icon-cogwheel::before {
  content: "\ea6b";
}

.icon-settings-gear::before {
  content: "\ea6c";
}

.icon-gear::before {
  content: "\ea6d";
}

.icon-wrench-tool::before {
  content: "\ea6e";
}

.icon-network-communication::before {
  content: "\ea6f";
}

.icon-network-connection::before {
  content: "\ea70";
}

.icon-hotspot::before {
  content: "\ea71";
}

.icon-e-add::before {
  content: "\ea72";
}

.icon-e-delete::before {
  content: "\ea73";
}

.icon-e-remove::before {
  content: "\ea74";
}

.icon-i-add::before {
  content: "\ea75";
}

.icon-i-delete::before {
  content: "\ea76";
}

.icon-i-remove::before {
  content: "\ea77";
}

.icon-s-add::before {
  content: "\ea78";
}

.icon-add::before {
  content: "\ea79";
}

.icon-s-delete::before {
  content: "\ea7a";
}

.icon-delete::before {
  content: "\ea7b";
}

.icon-s-remove::before {
  content: "\ea7c";
}

.icon-dock-left::before {
  content: "\ea7d";
}

.icon-dock-right::before {
  content: "\ea7e";
}

.icon-dock-top::before {
  content: "\ea7f";
}

.icon-drop::before {
  content: "\ea80";
}

.icon-pen-2::before {
  content: "\ea81";
}

.icon-pen::before {
  content: "\ea82";
}

.icon-i-edit::before {
  content: "\ea83";
}

.icon-edit::before {
  content: "\ea84";
}

.icon-n-edit::before {
  content: "\ea85";
}

.icon-d-edit::before {
  content: "\ea86";
}

.icon-s-edit::before {
  content: "\ea87";
}

.icon-c-edit::before {
  content: "\ea88";
}

.icon-letter::before {
  content: "\ea89";
}

.icon-mail::before {
  content: "\ea8a";
}

.icon-email::before {
  content: "\ea8b";
}

.icon-screen-maximize::before {
  content: "\ea8c";
}

.icon-screen-enlarge::before {
  content: "\ea8d";
}

.icon-preview::before {
  content: "\ea8e";
}

.icon-eye::before {
  content: "\ea8f";
}

.icon-b-preview::before {
  content: "\ea90";
}

.icon-b-eye::before {
  content: "\ea91";
}

.icon-heart::before {
  content: "\ea92";
}

.icon-star::before {
  content: "\ea93";
}

.icon-add-fav::before {
  content: "\ea94";
}

.icon-add-like::before {
  content: "\ea95";
}

.icon-remove-fav::before {
  content: "\ea96";
}

.icon-remove-like::before {
  content: "\ea97";
}

.icon-filter-check::before {
  content: "\ea98";
}

.icon-filter-remove::before {
  content: "\ea99";
}

.icon-filter::before {
  content: "\ea9a";
}

.icon-flame::before {
  content: "\ea9b";
}

.icon-fullsize::before {
  content: "\ea9c";
}

.icon-sort-tool::before {
  content: "\ea9d";
}

.icon-filter-tool::before {
  content: "\ea9e";
}

.icon-funnel::before {
  content: "\ea9f";
}

.icon-grid-interface::before {
  content: "\eaa0";
}

.icon-gallery-view::before {
  content: "\eaa1";
}

.icon-grid-view::before {
  content: "\eaa2";
}

.icon-gallery-layout::before {
  content: "\eaa3";
}

.icon-pencil::before {
  content: "\eaa4";
}

.icon-phone::before {
  content: "\eaa5";
}

.icon-telephone::before {
  content: "\eaa6";
}

.icon-phone-call-end::before {
  content: "\eaa7";
}

.icon-phone-call::before {
  content: "\eaa8";
}

.icon-playlist::before {
  content: "\eaa9";
}

.icon-system-preferences::before {
  content: "\eaaa";
}

.icon-setup-preferences::before {
  content: "\eaab";
}

.icon-setup-options::before {
  content: "\eaac";
}

.icon-setup-tools::before {
  content: "\eaad";
}

.icon-system-configuration::before {
  content: "\eaae";
}

.icon-configuration-tools::before {
  content: "\eaaf";
}

.icon-options::before {
  content: "\eab0";
}

.icon-preferences::before {
  content: "\eab1";
}

.icon-search-content::before {
  content: "\eab2";
}

.icon-security::before {
  content: "\eab3";
}

.icon-segmentation::before {
  content: "\eab4";
}

.icon-select::before {
  content: "\eab5";
}

.icon-send-message::before {
  content: "\eab6";
}

.icon-send::before {
  content: "\eab7";
}

.icon-grid-layout::before {
  content: "\eab8";
}

.icon-favorite::before {
  content: "\eab9";
}

.icon-add-favourite::before {
  content: "\eaba";
}

.icon-home-2::before {
  content: "\eabb";
}

.icon-home-3::before {
  content: "\eabc";
}

.icon-house::before {
  content: "\eabd";
}

.icon-remove-favourite::before {
  content: "\eabe";
}

.icon-hourglass::before {
  content: "\eabf";
}

.icon-infinite::before {
  content: "\eac0";
}

.icon-lab::before {
  content: "\eac1";
}

.icon-stack::before {
  content: "\eac2";
}

.icon-home::before {
  content: "\eac3";
}

.icon-plant-leaf::before {
  content: "\eac4";
}

.icon-like::before {
  content: "\eac5";
}

.icon-web-hyperlink::before {
  content: "\eac6";
}

.icon-web-url::before {
  content: "\eac7";
}

.icon-web-link::before {
  content: "\eac8";
}

.icon-hyperlink::before {
  content: "\eac9";
}

.icon-url::before {
  content: "\eaca";
}

.icon-link::before {
  content: "\eacb";
}

.icon-unlink::before {
  content: "\eacc";
}

.icon-link-broken::before {
  content: "\eacd";
}

.icon-padlock-unlocked::before {
  content: "\eace";
}

.icon-padlock::before {
  content: "\eacf";
}

.icon-unlocked::before {
  content: "\ead0";
}

.icon-lock::before {
  content: "\ead1";
}

.icon-menu-8::before {
  content: "\ead2";
}

.icon-menu-7::before {
  content: "\ead3";
}

.icon-menu-6::before {
  content: "\ead4";
}

.icon-menu-5::before {
  content: "\ead5";
}

.icon-menu-4::before {
  content: "\ead6";
}

.icon-menu-3::before {
  content: "\ead7";
}

.icon-menu-2::before {
  content: "\ead8";
}

.icon-menu::before {
  content: "\ead9";
}

.icon-metrics::before {
  content: "\eada";
}

.icon-networking::before {
  content: "\eadb";
}

.icon-megaphone::before {
  content: "\eadc";
}

.icon-paragraph::before {
  content: "\eadd";
}

.icon-mirror-tablet-phone::before {
  content: "\eade";
}

.icon-full-screen::before {
  content: "\eadf";
}

.icon-sidebar::before {
  content: "\eae0";
}

.icon-blog::before {
  content: "\eae1";
}

.icon-file-delete::before {
  content: "\eae2";
}

.icon-file-add::before {
  content: "\eae3";
}

.icon-fav-remove::before {
  content: "\eae4";
}

.icon-edit-note::before {
  content: "\eae5";
}

.icon-alarm-add::before {
  content: "\eae6";
}

.icon-timeline::before {
  content: "\eae7";
}

.icon-comment-add::before {
  content: "\eae8";
}

.icon-alarm-disable::before {
  content: "\eae9";
}

.icon-leaf::before {
  content: "\eaea";
}

.icon-at-sign::before {
  content: "\eaeb";
}

.icon-gantt::before {
  content: "\eaec";
}

.icon-wallet::before {
  content: "\eaed";
}

.icon-tag::before {
  content: "\eaee";
}

.icon-tag-remove::before {
  content: "\eaef";
}

.icon-tag-loyalty::before {
  content: "\eaf0";
}

.icon-tag-cut::before {
  content: "\eaf1";
}

.icon-shopping-tag::before {
  content: "\eaf2";
}

.icon-tag-check::before {
  content: "\eaf3";
}

.icon-tag-add::before {
  content: "\eaf4";
}

.icon-store::before {
  content: "\eaf5";
}

.icon-stock-2::before {
  content: "\eaf6";
}

.icon-sign-board::before {
  content: "\eaf7";
}

.icon-shop::before {
  content: "\eaf8";
}

.icon-scale-4::before {
  content: "\eaf9";
}

.icon-receipt::before {
  content: "\eafa";
}

.icon-receipt-list-43::before {
  content: "\eafb";
}

.icon-receipt-list-42::before {
  content: "\eafc";
}

.icon-newsletter::before {
  content: "\eafd";
}

.icon-market-play::before {
  content: "\eafe";
}

.icon-market-music::before {
  content: "\eaff";
}

.icon-list::before {
  content: "\eb00";
}

.icon-discount::before {
  content: "\eb01";
}

.icon-discount-2::before {
  content: "\eb02";
}

.icon-delivery-fast::before {
  content: "\eb03";
}

.icon-delivery-3::before {
  content: "\eb04";
}

.icon-delivery-2::before {
  content: "\eb05";
}

.icon-customer-support::before {
  content: "\eb06";
}

.icon-currency-exchange::before {
  content: "\eb07";
}

.icon-credit-card::before {
  content: "\eb08";
}

.icon-credit-card-in::before {
  content: "\eb09";
}

.icon-coupon::before {
  content: "\eb0a";
}

.icon-cheque-3::before {
  content: "\eb0b";
}

.icon-cheque-2::before {
  content: "\eb0c";
}

.icon-a-chart::before {
  content: "\eb0d";
}

.icon-cash-register::before {
  content: "\eb0e";
}

.icon-cart::before {
  content: "\eb0f";
}

.icon-cart-speed::before {
  content: "\eb10";
}

.icon-shopping-cart::before {
  content: "\eb11";
}

.icon-cart-simple-remove::before {
  content: "\eb12";
}

.icon-add-to-cart::before {
  content: "\eb13";
}

.icon-cart-simple-add::before {
  content: "\eb14";
}

.icon-cart-return::before {
  content: "\eb15";
}

.icon-shopping-cart-2::before {
  content: "\eb16";
}

.icon-add-to-cart-2::before {
  content: "\eb17";
}

.icon-cart-favorite::before {
  content: "\eb18";
}

.icon-card-remove::before {
  content: "\eb19";
}

.icon-card-favorite::before {
  content: "\eb1a";
}

.icon-card-edit::before {
  content: "\eb1b";
}

.icon-payment-method::before {
  content: "\eb1c";
}

.icon-box-ribbon::before {
  content: "\eb1d";
}

.icon-box-2::before {
  content: "\eb1e";
}

.icon-board-2::before {
  content: "\eb1f";
}

.icon-bitcoin::before {
  content: "\eb20";
}

.icon-basket::before {
  content: "\eb21";
}

.icon-basket-simple::before {
  content: "\eb22";
}

.icon-basket-simple-remove::before {
  content: "\eb23";
}

.icon-basket-simple-add::before {
  content: "\eb24";
}

.icon-barcode-qr::before {
  content: "\eb25";
}

.icon-barcode::before {
  content: "\eb26";
}

.icon-bag-time::before {
  content: "\eb27";
}

.icon-bag-remove-22::before {
  content: "\eb28";
}

.icon-bag-remove-19::before {
  content: "\eb29";
}

.icon-bag-edit::before {
  content: "\eb2a";
}

.icon-bag-add-21::before {
  content: "\eb2b";
}

.icon-bag-20::before {
  content: "\eb2c";
}

.icon-bag-17::before {
  content: "\eb2d";
}

.icon-bag-16::before {
  content: "\eb2e";
}

.icon-shopping-bag::before {
  content: "\eb2f";
}

.icon-award::before {
  content: "\eb30";
}

.icon-woman-21::before {
  content: "\eb31";
}

.icon-voice-record::before {
  content: "\eb32";
}

.icon-user-frame-33::before {
  content: "\eb33";
}

.icon-user-frame-32::before {
  content: "\eb34";
}

.icon-user-frame-31::before {
  content: "\eb35";
}

.icon-single-body::before {
  content: "\eb36";
}

.icon-single-05::before {
  content: "\eb37";
}

.icon-single-04::before {
  content: "\eb38";
}

.icon-single-03::before {
  content: "\eb39";
}

.icon-single-02::before {
  content: "\eb3a";
}

.icon-single-01::before {
  content: "\eb3b";
}

.icon-police::before {
  content: "\eb3c";
}

.icon-pin-4::before {
  content: "\eb3d";
}

.icon-multiple::before {
  content: "\eb3e";
}

.icon-multiple-19::before {
  content: "\eb3f";
}

.icon-multiple-11::before {
  content: "\eb40";
}

.icon-mickey-mouse::before {
  content: "\eb41";
}

.icon-man::before {
  content: "\eb42";
}

.icon-man-20::before {
  content: "\eb43";
}

.icon-property::before {
  content: "\eb44";
}

.icon-focus::before {
  content: "\eb45";
}

.icon-disabled::before {
  content: "\eb46";
}

.icon-delete-30::before {
  content: "\eb47";
}

.icon-delete-28::before {
  content: "\eb48";
}

.icon-contacts-45::before {
  content: "\eb49";
}

.icon-contacts-44::before {
  content: "\eb4a";
}

.icon-circle-10::before {
  content: "\eb4b";
}

.icon-circle-09::before {
  content: "\eb4c";
}

.icon-circle-08::before {
  content: "\eb4d";
}

.icon-badge-15::before {
  content: "\eb4e";
}

.icon-badge-14::before {
  content: "\eb4f";
}

.icon-badge-13::before {
  content: "\eb50";
}

.icon-add-29::before {
  content: "\eb51";
}

.icon-present::before {
  content: "\eb52";
}

.icon-accessibility::before {
  content: "\eb53";
}

.icon-a-remove::before {
  content: "\eb54";
}

.icon-a-heart::before {
  content: "\eb55";
}

.icon-a-edit::before {
  content: "\eb56";
}

.icon-a-check::before {
  content: "\eb57";
}

.icon-a-add::before {
  content: "\eb58";
}

.icon-vespa-front::before {
  content: "\eb59";
}

.icon-truck-front::before {
  content: "\eb5a";
}

.icon-tram::before {
  content: "\eb5b";
}

.icon-train::before {
  content: "\eb5c";
}

.icon-tractor::before {
  content: "\eb5d";
}

.icon-skateboard-2::before {
  content: "\eb5e";
}

.icon-road-2::before {
  content: "\eb5f";
}

.icon-plane-18::before {
  content: "\eb60";
}

.icon-plane-17::before {
  content: "\eb61";
}

.icon-moto::before {
  content: "\eb62";
}

.icon-light-traffic::before {
  content: "\eb63";
}

.icon-helmet::before {
  content: "\eb64";
}

.icon-helicopter::before {
  content: "\eb65";
}

.icon-bag-add-18::before {
  content: "\eb66";
}

.icon-car-front::before {
  content: "\eb67";
}

.icon-car-2::before {
  content: "\eb68";
}

.icon-bus-front-12::before {
  content: "\eb69";
}

.icon-bus-front-10::before {
  content: "\eb6a";
}

.icon-boat::before {
  content: "\eb6b";
}

.icon-boat-small-03::before {
  content: "\eb6c";
}

.icon-boat-small-02::before {
  content: "\eb6d";
}

.icon-bike::before {
  content: "\eb6e";
}

.icon-air-baloon::before {
  content: "\eb6f";
}

.icon-bike-bmx::before {
  content: "\eb70";
}

.icon-cycling::before {
  content: "\eb71";
}

.icon-car-parking::before {
  content: "\eb72";
}

.icon-car-connect::before {
  content: "\eb73";
}

.icon-car-wash::before {
  content: "\eb74";
}

.icon-car-accident::before {
  content: "\eb75";
}

.icon-sensor::before {
  content: "\eb76";
}

.icon-fuel-2::before {
  content: "\eb77";
}

.icon-seatbelt::before {
  content: "\eb78";
}

.icon-battery-level::before {
  content: "\eb79";
}

.icon-rim::before {
  content: "\eb7a";
}

.icon-wheel::before {
  content: "\eb7b";
}

.icon-wheel-2::before {
  content: "\eb7c";
}

.icon-car-lights::before {
  content: "\eb7d";
}

.icon-car-door::before {
  content: "\eb7e";
}

.icon-engine::before {
  content: "\eb7f";
}

.icon-washing-fluid::before {
  content: "\eb80";
}

.icon-car-ventilation::before {
  content: "\eb81";
}

.icon-hybrid::before {
  content: "\eb82";
}

.icon-trunk::before {
  content: "\eb83";
}

.icon-brakes::before {
  content: "\eb84";
}

.icon-ventilation::before {
  content: "\eb85";
}

.icon-seat::before {
  content: "\eb86";
}

.icon-parking-sensors::before {
  content: "\eb87";
}

.icon-oil::before {
  content: "\eb88";
}

.icon-engine-start::before {
  content: "\eb89";
}

.icon-parking::before {
  content: "\eb8a";
}

.icon-walk::before {
  content: "\eb8b";
}

.icon-airbag::before {
  content: "\eb8c";
}

.icon-window-responsive::before {
  content: "\eb8d";
}

.icon-window-paragraph::before {
  content: "\eb8e";
}

.icon-window-code::before {
  content: "\eb8f";
}

.icon-webpage::before {
  content: "\eb90";
}

.icon-wand-11::before {
  content: "\eb91";
}

.icon-vector::before {
  content: "\eb92";
}

.icon-usb::before {
  content: "\eb93";
}

.icon-ungroup::before {
  content: "\eb94";
}

.icon-transform::before {
  content: "\eb95";
}

.icon-transform-origin::before {
  content: "\eb96";
}

.icon-todo::before {
  content: "\eb97";
}

.icon-text::before {
  content: "\eb98";
}

.icon-text-2::before {
  content: "\eb99";
}

.icon-tablet::before {
  content: "\eb9a";
}

.icon-stamp::before {
  content: "\eb9b";
}

.icon-spray::before {
  content: "\eb9c";
}

.icon-slider::before {
  content: "\eb9d";
}

.icon-slice::before {
  content: "\eb9e";
}

.icon-sharpener::before {
  content: "\eb9f";
}

.icon-shapes::before {
  content: "\eba0";
}

.icon-shape-triangle::before {
  content: "\eba1";
}

.icon-shape-square::before {
  content: "\eba2";
}

.icon-shape-polygon::before {
  content: "\eba3";
}

.icon-shape-circle::before {
  content: "\eba4";
}

.icon-shape-adjust::before {
  content: "\eba5";
}

.icon-selection::before {
  content: "\eba6";
}

.icon-scissors::before {
  content: "\eba7";
}

.icon-scale-up::before {
  content: "\eba8";
}

.icon-scale-down::before {
  content: "\eba9";
}

.icon-scale-2::before {
  content: "\ebaa";
}

.icon-ruler-pencil::before {
  content: "\ebab";
}

.icon-mobile-phone::before {
  content: "\ebac";
}

.icon-pen-tool::before {
  content: "\ebad";
}

.icon-pen-23::before {
  content: "\ebae";
}

.icon-pen-01::before {
  content: "\ebaf";
}

.icon-path-unite::before {
  content: "\ebb0";
}

.icon-path-minus::before {
  content: "\ebb1";
}

.icon-path-intersect::before {
  content: "\ebb2";
}

.icon-path-exclude::before {
  content: "\ebb3";
}

.icon-patch-34::before {
  content: "\ebb4";
}

.icon-patch-19::before {
  content: "\ebb5";
}

.icon-pantone::before {
  content: "\ebb6";
}

.icon-palette::before {
  content: "\ebb7";
}

.icon-paint-bucket-40::before {
  content: "\ebb8";
}

.icon-paint-bucket-39::before {
  content: "\ebb9";
}

.icon-paint-brush::before {
  content: "\ebba";
}

.icon-paint-38::before {
  content: "\ebbb";
}

.icon-paint-37::before {
  content: "\ebbc";
}

.icon-paint-16::before {
  content: "\ebbd";
}

.icon-note-code::before {
  content: "\ebbe";
}

.icon-move-up-2::before {
  content: "\ebbf";
}

.icon-move-down-2::before {
  content: "\ebc0";
}

.icon-mouse-10::before {
  content: "\ebc1";
}

.icon-mouse-09::before {
  content: "\ebc2";
}

.icon-mouse-08::before {
  content: "\ebc3";
}

.icon-mirror::before {
  content: "\ebc4";
}

.icon-measure-big::before {
  content: "\ebc5";
}

.icon-measure-17::before {
  content: "\ebc6";
}

.icon-measure-02::before {
  content: "\ebc7";
}

.icon-marker::before {
  content: "\ebc8";
}

.icon-magnet::before {
  content: "\ebc9";
}

.icon-layout-25::before {
  content: "\ebca";
}

.icon-layout-11::before {
  content: "\ebcb";
}

.icon-layers::before {
  content: "\ebcc";
}

.icon-image::before {
  content: "\ebcd";
}

.icon-image-2::before {
  content: "\ebce";
}

.icon-html5::before {
  content: "\ebcf";
}

.icon-group::before {
  content: "\ebd0";
}

.icon-frame::before {
  content: "\ebd1";
}

.icon-flip-vertical::before {
  content: "\ebd2";
}

.icon-form::before {
  content: "\ebd3";
}

.icon-filter-organization::before {
  content: "\ebd4";
}

.icon-flip-horizontal::before {
  content: "\ebd5";
}

.icon-eraser-46::before {
  content: "\ebd6";
}

.icon-eraser-33::before {
  content: "\ebd7";
}

.icon-eraser-32::before {
  content: "\ebd8";
}

.icon-divider::before {
  content: "\ebd9";
}

.icon-distribute-vertical::before {
  content: "\ebda";
}

.icon-distribute-horizontal::before {
  content: "\ebdb";
}

.icon-design::before {
  content: "\ebdc";
}

.icon-cursor-text::before {
  content: "\ebdd";
}

.icon-cursor-pointer::before {
  content: "\ebde";
}

.icon-cursor-not-allowed::before {
  content: "\ebdf";
}

.icon-cursor-menu::before {
  content: "\ebe0";
}

.icon-cursor-load::before {
  content: "\ebe1";
}

.icon-cursor-grab::before {
  content: "\ebe2";
}

.icon-cursor-add::before {
  content: "\ebe3";
}

.icon-cursor-49::before {
  content: "\ebe4";
}

.icon-cursor-48::before {
  content: "\ebe5";
}

.icon-contrast-2::before {
  content: "\ebe6";
}

.icon-copy::before {
  content: "\ebe7";
}

.icon-crop::before {
  content: "\ebe8";
}

.icon-css3::before {
  content: "\ebe9";
}

.icon-command::before {
  content: "\ebea";
}

.icon-collection::before {
  content: "\ebeb";
}

.icon-code::before {
  content: "\ebec";
}

.icon-compass::before {
  content: "\ebed";
}

.icon-clone::before {
  content: "\ebee";
}

.icon-canvas::before {
  content: "\ebef";
}

.icon-button-2::before {
  content: "\ebf0";
}

.icon-bullet-list-70::before {
  content: "\ebf1";
}

.icon-bullet-list-69::before {
  content: "\ebf2";
}

.icon-bullet-list-68::before {
  content: "\ebf3";
}

.icon-bullet-list-67::before {
  content: "\ebf4";
}

.icon-bug::before {
  content: "\ebf5";
}

.icon-bucket::before {
  content: "\ebf6";
}

.icon-brush::before {
  content: "\ebf7";
}

.icon-book-open::before {
  content: "\ebf8";
}

.icon-book-bookmark::before {
  content: "\ebf9";
}

.icon-blend::before {
  content: "\ebfa";
}

.icon-artboard::before {
  content: "\ebfb";
}

.icon-apple::before {
  content: "\ebfc";
}

.icon-app::before {
  content: "\ebfd";
}

.icon-animation-32::before {
  content: "\ebfe";
}

.icon-animation-31::before {
  content: "\ebff";
}

.icon-animation-14::before {
  content: "\ec00";
}

.icon-angle::before {
  content: "\ec01";
}

.icon-android::before {
  content: "\ec02";
}

.icon-align-top::before {
  content: "\ec03";
}

.icon-align-right::before {
  content: "\ec04";
}

.icon-align-left::before {
  content: "\ec05";
}

.icon-align-center-vertical::before {
  content: "\ec06";
}

.icon-align-center-horizontal::before {
  content: "\ec07";
}

.icon-align-bottom::before {
  content: "\ec08";
}

.icon-album::before {
  content: "\ec09";
}

.icon-3d-29::before {
  content: "\ec0a";
}

.icon-3d-model::before {
  content: "\ec0b";
}

.icon-gradient::before {
  content: "\ec0c";
}

.icon-prototype::before {
  content: "\ec0d";
}

.icon-cards::before {
  content: "\ec0e";
}

.icon-transform-2d::before {
  content: "\ec0f";
}

.icon-car-taxi::before {
  content: "\ec10";
}

.icon-add-27::before {
  content: "\ec11";
}

.icon-border::before {
  content: "\ec12";
}

.icon-border-radius::before {
  content: "\ec13";
}

.icon-position::before {
  content: "\ec14";
}

.icon-shape-triangle-2::before {
  content: "\ec15";
}

.icon-shape-oval::before {
  content: "\ec16";
}

.icon-shape-rectangle::before {
  content: "\ec17";
}

.icon-shape-polygon-2::before {
  content: "\ec18";
}

.icon-shape-star::before {
  content: "\ec19";
}

.icon-shape-line::before {
  content: "\ec1a";
}

.icon-shape-arrow::before {
  content: "\ec1b";
}

.icon-shape-custom::before {
  content: "\ec1c";
}

.icon-design-system::before {
  content: "\ec1d";
}

.icon-git-commit::before {
  content: "\ec1e";
}

.icon-size-small::before {
  content: "\ec1f";
}

.icon-size-medium::before {
  content: "\ec20";
}

.icon-size-large::before {
  content: "\ec21";
}

.icon-transparent::before {
  content: "\ec22";
}

.icon-tool-blur::before {
  content: "\ec23";
}

.icon-texture::before {
  content: "\ec24";
}

.icon-mask-oval::before {
  content: "\ec25";
}

.icon-mask-rect::before {
  content: "\ec26";
}

.icon-grid-system::before {
  content: "\ec27";
}

.icon-components::before {
  content: "\ec28";
}

.icon-tool-select::before {
  content: "\ec29";
}

.icon-tool-hand::before {
  content: "\ec2a";
}

.icon-cable-49::before {
  content: "\ec2b";
}

.icon-cctv::before {
  content: "\ec2c";
}

.icon-cd-reader::before {
  content: "\ec2d";
}

.icon-computer-monitor::before {
  content: "\ec2e";
}

.icon-computer-old::before {
  content: "\ec2f";
}

.icon-computer::before {
  content: "\ec30";
}

.icon-connection::before {
  content: "\ec31";
}

.icon-controller-2::before {
  content: "\ec32";
}

.icon-controller-3::before {
  content: "\ec33";
}

.icon-desktop-screen::before {
  content: "\ec34";
}

.icon-desktop::before {
  content: "\ec35";
}

.icon-device-connection::before {
  content: "\ec36";
}

.icon-disk-reader::before {
  content: "\ec37";
}

.icon-disk::before {
  content: "\ec38";
}

.icon-dock::before {
  content: "\ec39";
}

.icon-firewall::before {
  content: "\ec3a";
}

.icon-hdmi::before {
  content: "\ec3b";
}

.icon-headphones-2::before {
  content: "\ec3c";
}

.icon-headphones::before {
  content: "\ec3d";
}

.icon-keyboard-hide::before {
  content: "\ec3e";
}

.icon-keyboard-wifi::before {
  content: "\ec3f";
}

.icon-keyboard-wireless::before {
  content: "\ec40";
}

.icon-keyboard::before {
  content: "\ec41";
}

.icon-l-add::before {
  content: "\ec42";
}

.icon-l-check::before {
  content: "\ec43";
}

.icon-l-remove::before {
  content: "\ec44";
}

.icon-l-system-update::before {
  content: "\ec45";
}

.icon-laptop-1::before {
  content: "\ec46";
}

.icon-laptop-2::before {
  content: "\ec47";
}

.icon-laptop::before {
  content: "\ec48";
}

.icon-lock-landscape::before {
  content: "\ec49";
}

.icon-lock-portrait::before {
  content: "\ec4a";
}

.icon-mic-2::before {
  content: "\ec4b";
}

.icon-mobile-button::before {
  content: "\ec4c";
}

.icon-mobile-camera::before {
  content: "\ec4d";
}

.icon-mobile-landscape::before {
  content: "\ec4e";
}

.icon-mobile-recharger-08::before {
  content: "\ec4f";
}

.icon-mobile-recharger-09::before {
  content: "\ec50";
}

.icon-mobile-toolbar::before {
  content: "\ec51";
}

.icon-mobile::before {
  content: "\ec52";
}

.icon-music-2::before {
  content: "\ec53";
}

.icon-navigation::before {
  content: "\ec54";
}

.icon-p-add::before {
  content: "\ec55";
}

.icon-p-check::before {
  content: "\ec56";
}

.icon-p-edit::before {
  content: "\ec57";
}

.icon-p-heart::before {
  content: "\ec58";
}

.icon-p-remove::before {
  content: "\ec59";
}

.icon-p-system-update::before {
  content: "\ec5a";
}

.icon-pci-card::before {
  content: "\ec5b";
}

.icon-player-19::before {
  content: "\ec5c";
}

.icon-player-48::before {
  content: "\ec5d";
}

.icon-print::before {
  content: "\ec5e";
}

.icon-printer::before {
  content: "\ec5f";
}

.icon-ram::before {
  content: "\ec60";
}

.icon-remote::before {
  content: "\ec61";
}

.icon-rotate::before {
  content: "\ec62";
}

.icon-signal::before {
  content: "\ec63";
}

.icon-sim-card::before {
  content: "\ec64";
}

.icon-socket-europe-1::before {
  content: "\ec65";
}

.icon-socket-europe-2::before {
  content: "\ec66";
}

.icon-socket-uk::before {
  content: "\ec67";
}

.icon-socket::before {
  content: "\ec68";
}

.icon-sync::before {
  content: "\ec69";
}

.icon-tablet-2::before {
  content: "\ec6a";
}

.icon-tablet-button::before {
  content: "\ec6b";
}

.icon-tablet-reader-31::before {
  content: "\ec6c";
}

.icon-tablet-reader-42::before {
  content: "\ec6d";
}

.icon-tablet-toolbar::before {
  content: "\ec6e";
}

.icon-tv-2::before {
  content: "\ec6f";
}

.icon-tv-old::before {
  content: "\ec70";
}

.icon-vpn::before {
  content: "\ec71";
}

.icon-watch-circle::before {
  content: "\ec72";
}

.icon-watch-time::before {
  content: "\ec73";
}

.icon-watch::before {
  content: "\ec74";
}

.icon-webcam-38::before {
  content: "\ec75";
}

.icon-webcam-39::before {
  content: "\ec76";
}

.icon-wifi-off::before {
  content: "\ec77";
}

.icon-wifi-protected::before {
  content: "\ec78";
}

.icon-wifi-router::before {
  content: "\ec79";
}

.icon-wifi::before {
  content: "\ec7a";
}

.icon-appointment::before {
  content: "\ec7b";
}

.icon-bookmark::before {
  content: "\ec7c";
}

.icon-bookmark-add::before {
  content: "\ec7d";
}

.icon-bookmark-delete::before {
  content: "\ec7e";
}

.icon-compass-2::before {
  content: "\ec7f";
}

.icon-compass-04::before {
  content: "\ec80";
}

.icon-compass-05::before {
  content: "\ec81";
}

.icon-compass-06::before {
  content: "\ec82";
}

.icon-crosshair::before {
  content: "\ec83";
}

.icon-directions::before {
  content: "\ec84";
}

.icon-explore::before {
  content: "\ec85";
}

.icon-flag-diagonal-34::before {
  content: "\ec86";
}

.icon-flag-diagonal-33::before {
  content: "\ec87";
}

.icon-flag-points-31::before {
  content: "\ec88";
}

.icon-flag-points-32::before {
  content: "\ec89";
}

.icon-flag-simple::before {
  content: "\ec8a";
}

.icon-pennant::before {
  content: "\ec8b";
}

.icon-flag::before {
  content: "\ec8c";
}

.icon-gps::before {
  content: "\ec8d";
}

.icon-journey-04::before {
  content: "\ec8e";
}

.icon-journey-05::before {
  content: "\ec8f";
}

.icon-journey-06::before {
  content: "\ec90";
}

.icon-journey-07::before {
  content: "\ec91";
}

.icon-journey-08::before {
  content: "\ec92";
}

.icon-m-add::before {
  content: "\ec93";
}

.icon-m-check::before {
  content: "\ec94";
}

.icon-m-edit::before {
  content: "\ec95";
}

.icon-m-heart::before {
  content: "\ec96";
}

.icon-m-remove::before {
  content: "\ec97";
}

.icon-m-security::before {
  content: "\ec98";
}

.icon-m-update::before {
  content: "\ec99";
}

.icon-map::before {
  content: "\ec9a";
}

.icon-marker-2::before {
  content: "\ec9b";
}

.icon-marker-3::before {
  content: "\ec9c";
}

.icon-pin-add-2::before {
  content: "\ec9d";
}

.icon-pin-add::before {
  content: "\ec9e";
}

.icon-pin-check::before {
  content: "\ec9f";
}

.icon-pin-copy::before {
  content: "\eca0";
}

.icon-pin-edit::before {
  content: "\eca1";
}

.icon-pin-heart::before {
  content: "\eca2";
}

.icon-pin-remove-2::before {
  content: "\eca3";
}

.icon-pin-remove::before {
  content: "\eca4";
}

.icon-pin-security::before {
  content: "\eca5";
}

.icon-pins::before {
  content: "\eca6";
}

.icon-position-marker::before {
  content: "\eca7";
}

.icon-radar::before {
  content: "\eca8";
}

.icon-treasure-map-40::before {
  content: "\eca9";
}

.icon-world::before {
  content: "\ecaa";
}

.icon-pin-3::before {
  content: "\ecab";
}

.icon-zoom-e::before {
  content: "\ecac";
}

.icon-zoom-triangles::before {
  content: "\ecad";
}

.icon-resize-v::before {
  content: "\ecae";
}

.icon-resize-h::before {
  content: "\ecaf";
}

.icon-subtitles::before {
  content: "\ecb0";
}

.icon-back-78::before {
  content: "\ecb1";
}

.icon-back-80::before {
  content: "\ecb2";
}

.icon-block-bottom-left::before {
  content: "\ecb3";
}

.icon-block-bottom-right::before {
  content: "\ecb4";
}

.icon-block-down::before {
  content: "\ecb5";
}

.icon-block-left::before {
  content: "\ecb6";
}

.icon-block-right::before {
  content: "\ecb7";
}

.icon-block-top-left::before {
  content: "\ecb8";
}

.icon-block-top-right::before {
  content: "\ecb9";
}

.icon-block-up::before {
  content: "\ecba";
}

.icon-bold-down::before {
  content: "\ecbb";
}

.icon-bold-left::before {
  content: "\ecbc";
}

.icon-bold-right::before {
  content: "\ecbd";
}

.icon-bold-up::before {
  content: "\ecbe";
}

.icon-centralize::before {
  content: "\ecbf";
}

.icon-circle-down-12::before {
  content: "\ecc0";
}

.icon-circle-down-40::before {
  content: "\ecc1";
}

.icon-circle-left-10::before {
  content: "\ecc2";
}

.icon-circle-left-38::before {
  content: "\ecc3";
}

.icon-circle-right-09::before {
  content: "\ecc4";
}

.icon-circle-right-37::before {
  content: "\ecc5";
}

.icon-circle-simple-down::before {
  content: "\ecc6";
}

.icon-circle-simple-left::before {
  content: "\ecc7";
}

.icon-circle-simple-right::before {
  content: "\ecc8";
}

.icon-circle-simple-up::before {
  content: "\ecc9";
}

.icon-circle-up-11::before {
  content: "\ecca";
}

.icon-circle-up-39::before {
  content: "\eccb";
}

.icon-cloud-download-95::before {
  content: "\eccc";
}

.icon-cloud-upload-96::before {
  content: "\eccd";
}

.icon-compare::before {
  content: "\ecce";
}

.icon-contrast::before {
  content: "\eccf";
}

.icon-conversion::before {
  content: "\ecd0";
}

.icon-corner-down-round::before {
  content: "\ecd1";
}

.icon-corner-down::before {
  content: "\ecd2";
}

.icon-corner-left-down::before {
  content: "\ecd3";
}

.icon-corner-left-round::before {
  content: "\ecd4";
}

.icon-corner-left::before {
  content: "\ecd5";
}

.icon-corner-right-down::before {
  content: "\ecd6";
}

.icon-corner-right-round::before {
  content: "\ecd7";
}

.icon-corner-right::before {
  content: "\ecd8";
}

.icon-corner-up-left::before {
  content: "\ecd9";
}

.icon-corner-up-right::before {
  content: "\ecda";
}

.icon-corner-up-round::before {
  content: "\ecdb";
}

.icon-corner-up::before {
  content: "\ecdc";
}

.icon-cross-down::before {
  content: "\ecdd";
}

.icon-cross-left::before {
  content: "\ecde";
}

.icon-cross-right::before {
  content: "\ecdf";
}

.icon-cross-up::before {
  content: "\ece0";
}

.icon-cross::before {
  content: "\ece1";
}

.icon-curved-next::before {
  content: "\ece2";
}

.icon-curved-previous::before {
  content: "\ece3";
}

.icon-delete-49::before {
  content: "\ece4";
}

.icon-delete-50::before {
  content: "\ece5";
}

.icon-diag-bottom-left::before {
  content: "\ece6";
}

.icon-diag-bottom-right::before {
  content: "\ece7";
}

.icon-diag-top-left::before {
  content: "\ece8";
}

.icon-diag-top-right::before {
  content: "\ece9";
}

.icon-direction-53::before {
  content: "\ecea";
}

.icon-direction-56::before {
  content: "\eceb";
}

.icon-direction::before {
  content: "\ecec";
}

.icon-disperse::before {
  content: "\eced";
}

.icon-dots-download::before {
  content: "\ecee";
}

.icon-dots-upload::before {
  content: "\ecef";
}

.icon-double-left::before {
  content: "\ecf0";
}

.icon-double-right::before {
  content: "\ecf1";
}

.icon-download-file::before {
  content: "\ecf2";
}

.icon-download::before {
  content: "\ecf3";
}

.icon-eject::before {
  content: "\ecf4";
}

.icon-enlarge-45::before {
  content: "\ecf5";
}

.icon-enlarge-46::before {
  content: "\ecf6";
}

.icon-enlarge-circle::before {
  content: "\ecf7";
}

.icon-enlarge-diagonal-43::before {
  content: "\ecf8";
}

.icon-enlarge-diagonal-44::before {
  content: "\ecf9";
}

.icon-enlarge-horizontal::before {
  content: "\ecfa";
}

.icon-enlarge-vertical::before {
  content: "\ecfb";
}

.icon-export::before {
  content: "\ecfc";
}

.icon-file-download-87::before {
  content: "\ecfd";
}

.icon-file-upload-86::before {
  content: "\ecfe";
}

.icon-format-left::before {
  content: "\ecff";
}

.icon-format-right::before {
  content: "\ed00";
}

.icon-fullscreen-70::before {
  content: "\ed01";
}

.icon-fullscreen-71::before {
  content: "\ed02";
}

.icon-fullscreen-76::before {
  content: "\ed03";
}

.icon-fullscreen-77::before {
  content: "\ed04";
}

.icon-fullscreen-double-74::before {
  content: "\ed05";
}

.icon-fullscreen-double-75::before {
  content: "\ed06";
}

.icon-fullscreen-split-72::before {
  content: "\ed07";
}

.icon-fullscreen-split-73::before {
  content: "\ed08";
}

.icon-hit-down::before {
  content: "\ed09";
}

.icon-hit-left::before {
  content: "\ed0a";
}

.icon-hit-right::before {
  content: "\ed0b";
}

.icon-hit-up::before {
  content: "\ed0c";
}

.icon-input-12::before {
  content: "\ed0d";
}

.icon-input-21::before {
  content: "\ed0e";
}

.icon-invert::before {
  content: "\ed0f";
}

.icon-launch-11::before {
  content: "\ed10";
}

.icon-launch-47::before {
  content: "\ed11";
}

.icon-log-in::before {
  content: "\ed12";
}

.icon-leave::before {
  content: "\ed13";
}

.icon-log-out::before {
  content: "\ed14";
}

.icon-logout::before {
  content: "\ed15";
}

.icon-loop-30::before {
  content: "\ed16";
}

.icon-loop-34::before {
  content: "\ed17";
}

.icon-loop-82::before {
  content: "\ed18";
}

.icon-merge::before {
  content: "\ed19";
}

.icon-minimal-down::before {
  content: "\ed1a";
}

.icon-minimal-left::before {
  content: "\ed1b";
}

.icon-minimal-right::before {
  content: "\ed1c";
}

.icon-minimal-up::before {
  content: "\ed1d";
}

.icon-move-down-right::before {
  content: "\ed1e";
}

.icon-move-down::before {
  content: "\ed1f";
}

.icon-move-left::before {
  content: "\ed20";
}

.icon-move-right::before {
  content: "\ed21";
}

.icon-move-up-left::before {
  content: "\ed22";
}

.icon-move-up::before {
  content: "\ed23";
}

.icon-open-in-browser::before {
  content: "\ed24";
}

.icon-priority-high::before {
  content: "\ed25";
}

.icon-priority-low::before {
  content: "\ed26";
}

.icon-push-next::before {
  content: "\ed27";
}

.icon-push-previous::before {
  content: "\ed28";
}

.icon-redo-10::before {
  content: "\ed29";
}

.icon-redo-26::before {
  content: "\ed2a";
}

.icon-redo-79::before {
  content: "\ed2b";
}

.icon-redo-81::before {
  content: "\ed2c";
}

.icon-refresh-68::before {
  content: "\ed2d";
}

.icon-refresh-69::before {
  content: "\ed2e";
}

.icon-replay::before {
  content: "\ed2f";
}

.icon-reply-all::before {
  content: "\ed30";
}

.icon-reply::before {
  content: "\ed31";
}

.icon-round-down::before {
  content: "\ed32";
}

.icon-round-left-down::before {
  content: "\ed33";
}

.icon-round-left::before {
  content: "\ed34";
}

.icon-round-right-down::before {
  content: "\ed35";
}

.icon-round-right::before {
  content: "\ed36";
}

.icon-round-up-left::before {
  content: "\ed37";
}

.icon-round-up-right::before {
  content: "\ed38";
}

.icon-round-up::before {
  content: "\ed39";
}

.icon-increase::before {
  content: "\ed3a";
}

.icon-enlarge::before {
  content: "\ed3b";
}

.icon-upload::before {
  content: "\ed3c";
}

.icon-social-sharing::before {
  content: "\ed3d";
}

.icon-sharing::before {
  content: "\ed3e";
}

.icon-share::before {
  content: "\ed3f";
}

.icon-shuffle-01::before {
  content: "\ed40";
}

.icon-shuffle-35::before {
  content: "\ed41";
}

.icon-shuffle-97::before {
  content: "\ed42";
}

.icon-shuffle-98::before {
  content: "\ed43";
}

.icon-simple-down::before {
  content: "\ed44";
}

.icon-simple-left::before {
  content: "\ed45";
}

.icon-simple-right::before {
  content: "\ed46";
}

.icon-simple-up::before {
  content: "\ed47";
}

.icon-skew-down::before {
  content: "\ed48";
}

.icon-skew-left::before {
  content: "\ed49";
}

.icon-skew-right::before {
  content: "\ed4a";
}

.icon-skew-up::before {
  content: "\ed4b";
}

.icon-small-down::before {
  content: "\ed4c";
}

.icon-small-left::before {
  content: "\ed4d";
}

.icon-small-right::before {
  content: "\ed4e";
}

.icon-small-triangle-down::before {
  content: "\ed4f";
}

.icon-small-triangle-left::before {
  content: "\ed50";
}

.icon-small-triangle-right::before {
  content: "\ed51";
}

.icon-small-triangle-up::before {
  content: "\ed52";
}

.icon-small-up::before {
  content: "\ed53";
}

.icon-split-33::before {
  content: "\ed54";
}

.icon-split-37::before {
  content: "\ed55";
}

.icon-square-corner-down-right::before {
  content: "\ed56";
}

.icon-square-corner-down-left::before {
  content: "\ed57";
}

.icon-square-corner-up-left::before {
  content: "\ed58";
}

.icon-square-corner-up-right::before {
  content: "\ed59";
}

.icon-square-down-06::before {
  content: "\ed5a";
}

.icon-square-down::before {
  content: "\ed5b";
}

.icon-data-download::before {
  content: "\ed5c";
}

.icon-square-left-04::before {
  content: "\ed5d";
}

.icon-square-left::before {
  content: "\ed5e";
}

.icon-square-right-03::before {
  content: "\ed5f";
}

.icon-square-right::before {
  content: "\ed60";
}

.icon-square-simple-down::before {
  content: "\ed61";
}

.icon-square-simple-left::before {
  content: "\ed62";
}

.icon-square-simple-right::before {
  content: "\ed63";
}

.icon-square-simple-up::before {
  content: "\ed64";
}

.icon-square-up-05::before {
  content: "\ed65";
}

.icon-square-up::before {
  content: "\ed66";
}

.icon-data-upload::before {
  content: "\ed67";
}

.icon-stre-down::before {
  content: "\ed68";
}

.icon-stre-left::before {
  content: "\ed69";
}

.icon-stre-right::before {
  content: "\ed6a";
}

.icon-stre-up::before {
  content: "\ed6b";
}

.icon-strong-down::before {
  content: "\ed6c";
}

.icon-strong-left::before {
  content: "\ed6d";
}

.icon-strong-right::before {
  content: "\ed6e";
}

.icon-strong-up::before {
  content: "\ed6f";
}

.icon-swap-horizontal::before {
  content: "\ed70";
}

.icon-swap-vertical::before {
  content: "\ed71";
}

.icon-system-update::before {
  content: "\ed72";
}

.icon-tail-down::before {
  content: "\ed73";
}

.icon-tail-left::before {
  content: "\ed74";
}

.icon-tail-right::before {
  content: "\ed75";
}

.icon-tail-triangle-down::before {
  content: "\ed76";
}

.icon-tail-triangle-left::before {
  content: "\ed77";
}

.icon-tail-triangle-right::before {
  content: "\ed78";
}

.icon-tail-triangle-up::before {
  content: "\ed79";
}

.icon-tail-up::before {
  content: "\ed7a";
}

.icon-trend-down::before {
  content: "\ed7b";
}

.icon-trend-up::before {
  content: "\ed7c";
}

.icon-triangle-down-20::before {
  content: "\ed7d";
}

.icon-triangle-down-65::before {
  content: "\ed7e";
}

.icon-triangle-down::before {
  content: "\ed7f";
}

.icon-triangle-left-18::before {
  content: "\ed80";
}

.icon-triangle-left-63::before {
  content: "\ed81";
}

.icon-triangle-left::before {
  content: "\ed82";
}

.icon-triangle-right-17::before {
  content: "\ed83";
}

.icon-triangle-right-62::before {
  content: "\ed84";
}

.icon-triangle-right::before {
  content: "\ed85";
}

.icon-triangle-up-19::before {
  content: "\ed86";
}

.icon-triangle-up-64::before {
  content: "\ed87";
}

.icon-triangle-up::before {
  content: "\ed88";
}

.icon-undo-25::before {
  content: "\ed89";
}

.icon-undo-29::before {
  content: "\ed8a";
}

.icon-upload-file::before {
  content: "\ed8b";
}

.icon-zoom-88::before {
  content: "\ed8c";
}

.icon-zoom-99::before {
  content: "\ed8d";
}

.icon-zoom-100::before {
  content: "\ed8e";
}

.icon-expand::before {
  content: "\ed8f";
}

.icon-2x-drag-down::before {
  content: "\ed90";
}

.icon-2x-drag-up::before {
  content: "\ed91";
}

.icon-active-38::before {
  content: "\ed92";
}

.icon-active-40::before {
  content: "\ed93";
}

.icon-camera::before {
  content: "\ed94";
}

.icon-double-tap::before {
  content: "\ed95";
}

.icon-drag-21::before {
  content: "\ed96";
}

.icon-drag-31::before {
  content: "\ed97";
}

.icon-drag-down::before {
  content: "\ed98";
}

.icon-drag-left::before {
  content: "\ed99";
}

.icon-drag-right::before {
  content: "\ed9a";
}

.icon-drag-up::before {
  content: "\ed9b";
}

.icon-grab::before {
  content: "\ed9c";
}

.icon-pin-2::before {
  content: "\ed9d";
}

.icon-pinch::before {
  content: "\ed9e";
}

.icon-rotate-22::before {
  content: "\ed9f";
}

.icon-scan::before {
  content: "\eda0";
}

.icon-swipe-right::before {
  content: "\eda1";
}

.icon-swipe-up::before {
  content: "\eda2";
}

.icon-tap-01::before {
  content: "\eda3";
}

.icon-tap-02::before {
  content: "\eda4";
}

.icon-align-center::before {
  content: "\eda5";
}

.icon-align-justify::before {
  content: "\eda6";
}

.icon-align-left-2::before {
  content: "\eda7";
}

.icon-align-right-2::before {
  content: "\eda8";
}

.icon-background::before {
  content: "\eda9";
}

.icon-bold::before {
  content: "\edaa";
}

.icon-capitalize::before {
  content: "\edab";
}

.icon-caps-small::before {
  content: "\edac";
}

.icon-color::before {
  content: "\edad";
}

.icon-edit-2::before {
  content: "\edae";
}

.icon-italic::before {
  content: "\edaf";
}

.icon-line-height::before {
  content: "\edb0";
}

.icon-list-bullet::before {
  content: "\edb1";
}

.icon-list-numbers::before {
  content: "\edb2";
}

.icon-margin-left::before {
  content: "\edb3";
}

.icon-margin-right::before {
  content: "\edb4";
}

.icon-quote::before {
  content: "\edb5";
}

.icon-scale-horizontal::before {
  content: "\edb6";
}

.icon-scale-vertical::before {
  content: "\edb7";
}

.icon-size::before {
  content: "\edb8";
}

.icon-strikethrough::before {
  content: "\edb9";
}

.icon-subscript::before {
  content: "\edba";
}

.icon-superscript::before {
  content: "\edbb";
}

.icon-tracking::before {
  content: "\edbc";
}

.icon-underline::before {
  content: "\edbd";
}

.icon-add-file::before {
  content: "\edbe";
}

.icon-archive-3d-content::before {
  content: "\edbf";
}

.icon-archive-check::before {
  content: "\edc0";
}

.icon-archive-content::before {
  content: "\edc1";
}

.icon-archive-paper::before {
  content: "\edc2";
}

.icon-archive::before {
  content: "\edc3";
}

.icon-book-07::before {
  content: "\edc4";
}

.icon-box::before {
  content: "\edc5";
}

.icon-copy-2::before {
  content: "\edc6";
}

.icon-drawer::before {
  content: "\edc7";
}

.icon-folder-13::before {
  content: "\edc8";
}

.icon-folder-14::before {
  content: "\edc9";
}

.icon-folder-15::before {
  content: "\edca";
}

.icon-folder-16::before {
  content: "\edcb";
}

.icon-folder-17::before {
  content: "\edcc";
}

.icon-folder-18::before {
  content: "\edcd";
}

.icon-folder-add::before {
  content: "\edce";
}

.icon-folder-remove::before {
  content: "\edcf";
}

.icon-notebook::before {
  content: "\edd0";
}

.icon-document::before {
  content: "\edd1";
}

.icon-remove-file::before {
  content: "\edd2";
}

.icon-single-content-02::before {
  content: "\edd3";
}

.icon-single-content-03::before {
  content: "\edd4";
}

.icon-single-copies::before {
  content: "\edd5";
}

.icon-single-copy-04::before {
  content: "\edd6";
}

.icon-single-copy-06::before {
  content: "\edd7";
}

.icon-file-content::before {
  content: "\edd8";
}

.icon-single-folded::before {
  content: "\edd9";
}

.icon-file-paragraph::before {
  content: "\edda";
}

.icon-single::before {
  content: "\eddb";
}

.icon-bat::before {
  content: "\eddc";
}

.icon-bones::before {
  content: "\eddd";
}

.icon-boot-2::before {
  content: "\edde";
}

.icon-candy-2::before {
  content: "\eddf";
}

.icon-cat::before {
  content: "\ede0";
}

.icon-cauldron::before {
  content: "\ede1";
}

.icon-chimney::before {
  content: "\ede2";
}

.icon-bow::before {
  content: "\ede3";
}

.icon-coffin::before {
  content: "\ede4";
}

.icon-deer::before {
  content: "\ede5";
}

.icon-egg-38::before {
  content: "\ede6";
}

.icon-egg-39::before {
  content: "\ede7";
}

.icon-ghost-2::before {
  content: "\ede8";
}

.icon-gift::before {
  content: "\ede9";
}

.icon-glove::before {
  content: "\edea";
}

.icon-grave::before {
  content: "\edeb";
}

.icon-light-2::before {
  content: "\edec";
}

.icon-love-card::before {
  content: "\eded";
}

.icon-owl::before {
  content: "\edee";
}

.icon-pumpkin::before {
  content: "\edef";
}

.icon-rabbit::before {
  content: "\edf0";
}

.icon-santa-hat::before {
  content: "\edf1";
}

.icon-sickle::before {
  content: "\edf2";
}

.icon-snow-ball::before {
  content: "\edf3";
}

.icon-snowman::before {
  content: "\edf4";
}

.icon-spider::before {
  content: "\edf5";
}

.icon-tree-ball::before {
  content: "\edf6";
}

.icon-tree::before {
  content: "\edf7";
}

.icon-vampire::before {
  content: "\edf8";
}

.icon-witch-hat::before {
  content: "\edf9";
}

.icon-wolf::before {
  content: "\edfa";
}

.icon-xmas-sock::before {
  content: "\edfb";
}

.icon-zombie::before {
  content: "\edfc";
}

.icon-alien-29::before {
  content: "\edfd";
}

.icon-alien-33::before {
  content: "\edfe";
}

.icon-anchor::before {
  content: "\edff";
}

.icon-astronaut::before {
  content: "\ee00";
}

.icon-axe::before {
  content: "\ee01";
}

.icon-baby-3::before {
  content: "\ee02";
}

.icon-baby-bottle::before {
  content: "\ee03";
}

.icon-baloon::before {
  content: "\ee04";
}

.icon-battery::before {
  content: "\ee05";
}

.icon-bear-2::before {
  content: "\ee06";
}

.icon-swipe-left::before {
  content: "\ee07";
}

.icon-binocular::before {
  content: "\ee08";
}

.icon-archery::before {
  content: "\ee09";
}

.icon-bowling-ball::before {
  content: "\ee0a";
}

.icon-billiard-ball::before {
  content: "\ee0b";
}

.icon-stretch::before {
  content: "\ee0c";
}

.icon-controller::before {
  content: "\ee0d";
}

.icon-diamond::before {
  content: "\ee0e";
}

.icon-dice::before {
  content: "\ee0f";
}

.icon-broom::before {
  content: "\ee10";
}

.icon-cone::before {
  content: "\ee11";
}

.icon-globe-2::before {
  content: "\ee12";
}

.icon-key::before {
  content: "\ee13";
}

.icon-swipe-bottom::before {
  content: "\ee14";
}

.icon-hut::before {
  content: "\ee15";
}

.icon-access-key::before {
  content: "\ee16";
}

.icon-planet::before {
  content: "\ee17";
}

.icon-puzzle-09::before {
  content: "\ee18";
}

.icon-puzzle-10::before {
  content: "\ee19";
}

.icon-light-3::before {
  content: "\ee1a";
}

.icon-skull-2::before {
  content: "\ee1b";
}

.icon-lamp-2::before {
  content: "\ee1c";
}

.icon-lifering::before {
  content: "\ee1d";
}

.icon-support::before {
  content: "\ee1e";
}

.icon-umbrella-13::before {
  content: "\ee1f";
}

.icon-umbrella-14::before {
  content: "\ee20";
}

.icon-wool-ball::before {
  content: "\ee21";
}

.icon-3d::before {
  content: "\ee22";
}

.icon-album-2::before {
  content: "\ee23";
}

.icon-audio-91::before {
  content: "\ee24";
}

.icon-audio-92::before {
  content: "\ee25";
}

.icon-white-balance::before {
  content: "\ee26";
}

.icon-brightness-46::before {
  content: "\ee27";
}

.icon-brightness-47::before {
  content: "\ee28";
}

.icon-button-eject::before {
  content: "\ee29";
}

.icon-button-next::before {
  content: "\ee2a";
}

.icon-button-pause::before {
  content: "\ee2b";
}

.icon-button-play::before {
  content: "\ee2c";
}

.icon-button-power::before {
  content: "\ee2d";
}

.icon-button-previous::before {
  content: "\ee2e";
}

.icon-button-record::before {
  content: "\ee2f";
}

.icon-button-rewind::before {
  content: "\ee30";
}

.icon-button-skip::before {
  content: "\ee31";
}

.icon-button-stop::before {
  content: "\ee32";
}

.icon-camera-18::before {
  content: "\ee33";
}

.icon-camera-19::before {
  content: "\ee34";
}

.icon-camera-20::before {
  content: "\ee35";
}

.icon-camera-ban-37::before {
  content: "\ee36";
}

.icon-camera-compact::before {
  content: "\ee37";
}

.icon-camera-square-57::before {
  content: "\ee38";
}

.icon-camera-time::before {
  content: "\ee39";
}

.icon-countdown-34::before {
  content: "\ee3a";
}

.icon-edit-color::before {
  content: "\ee3b";
}

.icon-edit-contrast-42::before {
  content: "\ee3c";
}

.icon-edit-contrast-43::before {
  content: "\ee3d";
}

.icon-edit-saturation::before {
  content: "\ee3e";
}

.icon-flash-21::before {
  content: "\ee3f";
}

.icon-flash-24::before {
  content: "\ee40";
}

.icon-flash-29::before {
  content: "\ee41";
}

.icon-focus-32::before {
  content: "\ee42";
}

.icon-focus-38::before {
  content: "\ee43";
}

.icon-focus-40::before {
  content: "\ee44";
}

.icon-focus-circle::before {
  content: "\ee45";
}

.icon-frame-12::before {
  content: "\ee46";
}

.icon-frame-41::before {
  content: "\ee47";
}

.icon-grid::before {
  content: "\ee48";
}

.icon-headphones-mic::before {
  content: "\ee49";
}

.icon-headphones-3::before {
  content: "\ee4a";
}

.icon-image-01::before {
  content: "\ee4b";
}

.icon-image-02::before {
  content: "\ee4c";
}

.icon-image-05::before {
  content: "\ee4d";
}

.icon-image-add::before {
  content: "\ee4e";
}

.icon-image-delete::before {
  content: "\ee4f";
}

.icon-knob::before {
  content: "\ee50";
}

.icon-layers-2::before {
  content: "\ee51";
}

.icon-spaceship::before {
  content: "\ee52";
}

.icon-lens-56::before {
  content: "\ee53";
}

.icon-leaf-36::before {
  content: "\ee54";
}

.icon-mic::before {
  content: "\ee55";
}

.icon-lens-31::before {
  content: "\ee56";
}

.icon-macro::before {
  content: "\ee57";
}

.icon-music-album::before {
  content: "\ee58";
}

.icon-night::before {
  content: "\ee59";
}

.icon-note-03::before {
  content: "\ee5a";
}

.icon-note-04::before {
  content: "\ee5b";
}

.icon-picture::before {
  content: "\ee5c";
}

.icon-play-68::before {
  content: "\ee5d";
}

.icon-movie-62::before {
  content: "\ee5e";
}

.icon-player::before {
  content: "\ee5f";
}

.icon-polaroid-add::before {
  content: "\ee60";
}

.icon-polaroid-delete::before {
  content: "\ee61";
}

.icon-polaroid-multiple::before {
  content: "\ee62";
}

.icon-polaroid::before {
  content: "\ee63";
}

.icon-radio::before {
  content: "\ee64";
}

.icon-sd::before {
  content: "\ee65";
}

.icon-roll::before {
  content: "\ee66";
}

.icon-sound-wave::before {
  content: "\ee67";
}

.icon-speaker-01::before {
  content: "\ee68";
}

.icon-tape::before {
  content: "\ee69";
}

.icon-ticket::before {
  content: "\ee6a";
}

.icon-speaker-05::before {
  content: "\ee6b";
}

.icon-event-ticket::before {
  content: "\ee6c";
}

.icon-touch::before {
  content: "\ee6d";
}

.icon-tripod::before {
  content: "\ee6e";
}

.icon-video-64::before {
  content: "\ee6f";
}

.icon-video-65::before {
  content: "\ee70";
}

.icon-video-66::before {
  content: "\ee71";
}

.icon-video-67::before {
  content: "\ee72";
}

.icon-videocamera-71::before {
  content: "\ee73";
}

.icon-videocamera-72::before {
  content: "\ee74";
}

.icon-volume-93::before {
  content: "\ee75";
}

.icon-volume-97::before {
  content: "\ee76";
}

.icon-volume-down::before {
  content: "\ee77";
}

.icon-volume-up::before {
  content: "\ee78";
}

.icon-bee::before {
  content: "\ee79";
}

.icon-butterfly::before {
  content: "\ee7a";
}

.icon-chicken-2::before {
  content: "\ee7b";
}

.icon-clover::before {
  content: "\ee7c";
}

.icon-collar::before {
  content: "\ee7d";
}

.icon-cow::before {
  content: "\ee7e";
}

.icon-dog-house::before {
  content: "\ee7f";
}

.icon-dog::before {
  content: "\ee80";
}

.icon-flower-05::before {
  content: "\ee81";
}

.icon-flower-06::before {
  content: "\ee82";
}

.icon-flower-07::before {
  content: "\ee83";
}

.icon-food-dog::before {
  content: "\ee84";
}

.icon-pet-food::before {
  content: "\ee85";
}

.icon-mountain::before {
  content: "\ee86";
}

.icon-mushroom::before {
  content: "\ee87";
}

.icon-panda::before {
  content: "\ee88";
}

.icon-paw::before {
  content: "\ee89";
}

.icon-pig-2::before {
  content: "\ee8a";
}

.icon-plant-ground::before {
  content: "\ee8b";
}

.icon-plant-vase::before {
  content: "\ee8c";
}

.icon-rat::before {
  content: "\ee8d";
}

.icon-tree-01::before {
  content: "\ee8e";
}

.icon-tree-02::before {
  content: "\ee8f";
}

.icon-tree-03::before {
  content: "\ee90";
}

.icon-turtle::before {
  content: "\ee91";
}

.icon-wood::before {
  content: "\ee92";
}

.icon-agenda::before {
  content: "\ee93";
}

.icon-atm::before {
  content: "\ee94";
}

.icon-cockade::before {
  content: "\ee95";
}

.icon-award-49::before {
  content: "\ee96";
}

.icon-certificate::before {
  content: "\ee97";
}

.icon-badge::before {
  content: "\ee98";
}

.icon-bank::before {
  content: "\ee99";
}

.icon-board-27::before {
  content: "\ee9a";
}

.icon-board-28::before {
  content: "\ee9b";
}

.icon-books::before {
  content: "\ee9c";
}

.icon-briefcase-24::before {
  content: "\ee9d";
}

.icon-briefcase-25::before {
  content: "\ee9e";
}

.icon-briefcase-26::before {
  content: "\ee9f";
}

.icon-building::before {
  content: "\eea0";
}

.icon-bulb-61::before {
  content: "\eea1";
}

.icon-bulb-62::before {
  content: "\eea2";
}

.icon-bulb-63::before {
  content: "\eea3";
}

.icon-businessman-03::before {
  content: "\eea4";
}

.icon-businessman-04::before {
  content: "\eea5";
}

.icon-calculator::before {
  content: "\eea6";
}

.icon-chair::before {
  content: "\eea7";
}

.icon-chart-bar-32::before {
  content: "\eea8";
}

.icon-chart-growth::before {
  content: "\eea9";
}

.icon-chart-pie-35::before {
  content: "\eeaa";
}

.icon-chart-pie-36::before {
  content: "\eeab";
}

.icon-chart::before {
  content: "\eeac";
}

.icon-cheque::before {
  content: "\eead";
}

.icon-coins::before {
  content: "\eeae";
}

.icon-connect::before {
  content: "\eeaf";
}

.icon-contacts::before {
  content: "\eeb0";
}

.icon-movie-61::before {
  content: "\eeb1";
}

.icon-play-69::before {
  content: "\eeb2";
}

.icon-currency-pound::before {
  content: "\eeb3";
}

.icon-currency-yen::before {
  content: "\eeb4";
}

.icon-factory::before {
  content: "\eeb5";
}

.icon-globe::before {
  content: "\eeb6";
}

.icon-archery-target::before {
  content: "\eeb7";
}

.icon-goal-65::before {
  content: "\eeb8";
}

.icon-hammer::before {
  content: "\eeb9";
}

.icon-handout::before {
  content: "\eeba";
}

.icon-hat::before {
  content: "\eebb";
}

.icon-hierarchy-53::before {
  content: "\eebc";
}

.icon-shovel::before {
  content: "\eebd";
}

.icon-currency-euro::before {
  content: "\eebe";
}

.icon-money-11::before {
  content: "\eebf";
}

.icon-money-12::before {
  content: "\eec0";
}

.icon-currency-dollar::before {
  content: "\eec1";
}

.icon-math::before {
  content: "\eec2";
}

.icon-money-bag::before {
  content: "\eec3";
}

.icon-net::before {
  content: "\eec4";
}

.icon-money-13::before {
  content: "\eec5";
}

.icon-plug::before {
  content: "\eec6";
}

.icon-progress::before {
  content: "\eec7";
}

.icon-safe::before {
  content: "\eec8";
}

.icon-sign::before {
  content: "\eec9";
}

.icon-signature::before {
  content: "\eeca";
}

.icon-stock::before {
  content: "\eecb";
}

.icon-strategy::before {
  content: "\eecc";
}

.icon-tie-01::before {
  content: "\eecd";
}

.icon-tie-02::before {
  content: "\eece";
}

.icon-wallet-43::before {
  content: "\eecf";
}

.icon-wallet-44::before {
  content: "\eed0";
}

.icon-pin::before {
  content: "\eed1";
}

.icon-baby::before {
  content: "\eed2";
}

.icon-notes::before {
  content: "\eed3";
}

.icon-bag-21::before {
  content: "\eed4";
}

.icon-bag-22::before {
  content: "\eed5";
}

.icon-belt::before {
  content: "\eed6";
}

.icon-apron::before {
  content: "\eed7";
}

.icon-boot::before {
  content: "\eed8";
}

.icon-bra::before {
  content: "\eed9";
}

.icon-boot-woman::before {
  content: "\eeda";
}

.icon-cap::before {
  content: "\eedb";
}

.icon-corset::before {
  content: "\eedc";
}

.icon-dress-woman::before {
  content: "\eedd";
}

.icon-flip::before {
  content: "\eede";
}

.icon-glasses::before {
  content: "\eedf";
}

.icon-hat-2::before {
  content: "\eee0";
}

.icon-hat-top::before {
  content: "\eee1";
}

.icon-iron::before {
  content: "\eee2";
}

.icon-jeans-41::before {
  content: "\eee3";
}

.icon-jeans-pocket::before {
  content: "\eee4";
}

.icon-long-sleeve::before {
  content: "\eee5";
}

.icon-makeup::before {
  content: "\eee6";
}

.icon-button::before {
  content: "\eee7";
}

.icon-backpack::before {
  content: "\eee8";
}

.icon-shirt-buttons::before {
  content: "\eee9";
}

.icon-shirt-neck::before {
  content: "\eeea";
}

.icon-shirt::before {
  content: "\eeeb";
}

.icon-shoe-man::before {
  content: "\eeec";
}

.icon-gym-shoes::before {
  content: "\eeed";
}

.icon-shoe-woman::before {
  content: "\eeee";
}

.icon-skirt::before {
  content: "\eeef";
}

.icon-slacks-12::before {
  content: "\eef0";
}

.icon-sock::before {
  content: "\eef1";
}

.icon-tie-bow::before {
  content: "\eef2";
}

.icon-tshirt-53::before {
  content: "\eef3";
}

.icon-tshirt-54::before {
  content: "\eef4";
}

.icon-underwear-man::before {
  content: "\eef5";
}

.icon-underwear::before {
  content: "\eef6";
}

.icon-vest::before {
  content: "\eef7";
}

.icon-wash::before {
  content: "\eef8";
}

.icon-angry-10::before {
  content: "\eef9";
}

.icon-angry-44::before {
  content: "\eefa";
}

.icon-big-eyes::before {
  content: "\eefb";
}

.icon-big-smile::before {
  content: "\eefc";
}

.icon-bigmouth::before {
  content: "\eefd";
}

.icon-bomb::before {
  content: "\eefe";
}

.icon-cake::before {
  content: "\eeff";
}

.icon-cry-15::before {
  content: "\ef00";
}

.icon-cute::before {
  content: "\ef01";
}

.icon-devil::before {
  content: "\ef02";
}

.icon-fist::before {
  content: "\ef03";
}

.icon-ghost::before {
  content: "\ef04";
}

.icon-happy-sun::before {
  content: "\ef05";
}

.icon-kiss::before {
  content: "\ef06";
}

.icon-laugh-35::before {
  content: "\ef07";
}

.icon-thumb-down::before {
  content: "\ef08";
}

.icon-thumb-up::before {
  content: "\ef09";
}

.icon-manga-62::before {
  content: "\ef0a";
}

.icon-manga-63::before {
  content: "\ef0b";
}

.icon-monster::before {
  content: "\ef0c";
}

.icon-nerd-22::before {
  content: "\ef0d";
}

.icon-percentage-39::before {
  content: "\ef0e";
}

.icon-puzzled::before {
  content: "\ef0f";
}

.icon-quite-happy::before {
  content: "\ef10";
}

.icon-robot::before {
  content: "\ef11";
}

.icon-sad::before {
  content: "\ef12";
}

.icon-scarf::before {
  content: "\ef13";
}

.icon-shark::before {
  content: "\ef14";
}

.icon-shy::before {
  content: "\ef15";
}

.icon-skull::before {
  content: "\ef16";
}

.icon-smile::before {
  content: "\ef17";
}

.icon-speechless::before {
  content: "\ef18";
}

.icon-sunglasses-48::before {
  content: "\ef19";
}

.icon-sunglasses-49::before {
  content: "\ef1a";
}

.icon-surprise::before {
  content: "\ef1b";
}

.icon-satisfied::before {
  content: "\ef1c";
}

.icon-ring::before {
  content: "\ef1d";
}

.icon-what::before {
  content: "\ef1e";
}

.icon-baby-2::before {
  content: "\ef1f";
}

.icon-banana::before {
  content: "\ef20";
}

.icon-barbecue-tools::before {
  content: "\ef21";
}

.icon-beer-95::before {
  content: "\ef22";
}

.icon-beer-96::before {
  content: "\ef23";
}

.icon-beverage::before {
  content: "\ef24";
}

.icon-bottle-wine::before {
  content: "\ef25";
}

.icon-bottle::before {
  content: "\ef26";
}

.icon-bowl::before {
  content: "\ef27";
}

.icon-bread::before {
  content: "\ef28";
}

.icon-broccoli::before {
  content: "\ef29";
}

.icon-bacon::before {
  content: "\ef2a";
}

.icon-cake-slice::before {
  content: "\ef2b";
}

.icon-candle::before {
  content: "\ef2c";
}

.icon-candy::before {
  content: "\ef2d";
}

.icon-carrot::before {
  content: "\ef2e";
}

.icon-cake-13::before {
  content: "\ef2f";
}

.icon-virus::before {
  content: "\ef30";
}

.icon-cheese-24::before {
  content: "\ef31";
}

.icon-cheese-87::before {
  content: "\ef32";
}

.icon-cheeseburger::before {
  content: "\ef33";
}

.icon-chef-hat::before {
  content: "\ef34";
}

.icon-cherry::before {
  content: "\ef35";
}

.icon-chili::before {
  content: "\ef36";
}

.icon-noodles::before {
  content: "\ef37";
}

.icon-chips::before {
  content: "\ef38";
}

.icon-chocolate::before {
  content: "\ef39";
}

.icon-cocktail::before {
  content: "\ef3a";
}

.icon-coffe-long::before {
  content: "\ef3b";
}

.icon-coffee-long::before {
  content: "\ef3c";
}

.icon-coffee::before {
  content: "\ef3d";
}

.icon-cookies::before {
  content: "\ef3e";
}

.icon-food-course::before {
  content: "\ef3f";
}

.icon-crab::before {
  content: "\ef40";
}

.icon-croissant::before {
  content: "\ef41";
}

.icon-cutlery-75::before {
  content: "\ef42";
}

.icon-cutlery-76::before {
  content: "\ef43";
}

.icon-cutlery-77::before {
  content: "\ef44";
}

.icon-donut::before {
  content: "\ef45";
}

.icon-drink::before {
  content: "\ef46";
}

.icon-egg::before {
  content: "\ef47";
}

.icon-energy-drink::before {
  content: "\ef48";
}

.icon-fish::before {
  content: "\ef49";
}

.icon-glass::before {
  content: "\ef4a";
}

.icon-grape::before {
  content: "\ef4b";
}

.icon-poop::before {
  content: "\ef4c";
}

.icon-chicken::before {
  content: "\ef4d";
}

.icon-champagne::before {
  content: "\ef4e";
}

.icon-hob::before {
  content: "\ef4f";
}

.icon-hot-dog::before {
  content: "\ef50";
}

.icon-ice-cream-22::before {
  content: "\ef51";
}

.icon-ice-cream-72::before {
  content: "\ef52";
}

.icon-kettle::before {
  content: "\ef53";
}

.icon-knife::before {
  content: "\ef54";
}

.icon-lighter::before {
  content: "\ef55";
}

.icon-matches::before {
  content: "\ef56";
}

.icon-measuring-cup::before {
  content: "\ef57";
}

.icon-microwave::before {
  content: "\ef58";
}

.icon-milk::before {
  content: "\ef59";
}

.icon-moka::before {
  content: "\ef5a";
}

.icon-pizza-slice::before {
  content: "\ef5b";
}

.icon-plate::before {
  content: "\ef5c";
}

.icon-pot::before {
  content: "\ef5d";
}

.icon-recipe-book-46::before {
  content: "\ef5e";
}

.icon-muffin::before {
  content: "\ef5f";
}

.icon-mug::before {
  content: "\ef60";
}

.icon-sausage::before {
  content: "\ef61";
}

.icon-scale-3::before {
  content: "\ef62";
}

.icon-steak::before {
  content: "\ef63";
}

.icon-strawberry::before {
  content: "\ef64";
}

.icon-sushi::before {
  content: "\ef65";
}

.icon-tacos::before {
  content: "\ef66";
}

.icon-tea::before {
  content: "\ef67";
}

.icon-watermelon::before {
  content: "\ef68";
}

.icon-recipe-book-47::before {
  content: "\ef69";
}

.icon-pan::before {
  content: "\ef6a";
}

.icon-l-circle::before {
  content: "\ef6b";
}

.icon-l-circles::before {
  content: "\ef6c";
}

.icon-refresh-01::before {
  content: "\ef6d";
}

.icon-refresh-02::before {
  content: "\ef6e";
}

.icon-dots::before {
  content: "\ef6f";
}

.icon-circle::before {
  content: "\ef70";
}

.icon-loader-bars::before {
  content: "\ef71";
}

.icon-apple-2::before {
  content: "\ef72";
}

.icon-bag-49::before {
  content: "\ef73";
}

.icon-bag-50::before {
  content: "\ef74";
}

.icon-brain::before {
  content: "\ef75";
}

.icon-dna-27::before {
  content: "\ef76";
}

.icon-dna-38::before {
  content: "\ef77";
}

.icon-flask-2::before {
  content: "\ef78";
}

.icon-heartbeat::before {
  content: "\ef79";
}

.icon-height::before {
  content: "\ef7a";
}

.icon-hospital-32::before {
  content: "\ef7b";
}

.icon-hospital-33::before {
  content: "\ef7c";
}

.icon-hospital-34::before {
  content: "\ef7d";
}

.icon-humidity-26::before {
  content: "\ef7e";
}

.icon-humidity-52::before {
  content: "\ef7f";
}

.icon-molecule-39::before {
  content: "\ef80";
}

.icon-notebook-2::before {
  content: "\ef81";
}

.icon-patch::before {
  content: "\ef82";
}

.icon-pill-42::before {
  content: "\ef83";
}

.icon-pill-43::before {
  content: "\ef84";
}

.icon-pill-container-44::before {
  content: "\ef85";
}

.icon-pill-container-47::before {
  content: "\ef86";
}

.icon-pulse-chart::before {
  content: "\ef87";
}

.icon-pulse-sleep::before {
  content: "\ef88";
}

.icon-pulse::before {
  content: "\ef89";
}

.icon-steps::before {
  content: "\ef8a";
}

.icon-syringe::before {
  content: "\ef8b";
}

.icon-temperature-23::before {
  content: "\ef8c";
}

.icon-temperature-24::before {
  content: "\ef8d";
}

.icon-tooth::before {
  content: "\ef8e";
}

.icon-weed::before {
  content: "\ef8f";
}

.icon-weight-scale::before {
  content: "\ef90";
}

.icon-wheelchair::before {
  content: "\ef91";
}

.icon-woman::before {
  content: "\ef92";
}

.icon-air-conditioner::before {
  content: "\ef93";
}

.icon-armchair::before {
  content: "\ef94";
}

.icon-bed-09::before {
  content: "\ef95";
}

.icon-bed-23::before {
  content: "\ef96";
}

.icon-cabinet::before {
  content: "\ef97";
}

.icon-cactus::before {
  content: "\ef98";
}

.icon-chair-2::before {
  content: "\ef99";
}

.icon-coat-hanger::before {
  content: "\ef9a";
}

.icon-coffee-2::before {
  content: "\ef9b";
}

.icon-cradle::before {
  content: "\ef9c";
}

.icon-curtain::before {
  content: "\ef9d";
}

.icon-desk::before {
  content: "\ef9e";
}

.icon-door::before {
  content: "\ef9f";
}

.icon-drawer-2::before {
  content: "\efa0";
}

.icon-fridge::before {
  content: "\efa1";
}

.icon-hanger::before {
  content: "\efa2";
}

.icon-iron-2::before {
  content: "\efa3";
}

.icon-lamp-floor::before {
  content: "\efa4";
}

.icon-lamp::before {
  content: "\efa5";
}

.icon-library::before {
  content: "\efa6";
}

.icon-light::before {
  content: "\efa7";
}

.icon-mixer::before {
  content: "\efa8";
}

.icon-oven::before {
  content: "\efa9";
}

.icon-shower::before {
  content: "\efaa";
}

.icon-sink-wash::before {
  content: "\efab";
}

.icon-sink::before {
  content: "\efac";
}

.icon-storage-hanger::before {
  content: "\efad";
}

.icon-storage::before {
  content: "\efae";
}

.icon-toilet-paper::before {
  content: "\efaf";
}

.icon-toilet::before {
  content: "\efb0";
}

.icon-tv::before {
  content: "\efb1";
}

.icon-wardrobe::before {
  content: "\efb2";
}

.icon-wash-2::before {
  content: "\efb3";
}

.icon-agenda-bookmark::before {
  content: "\efb4";
}

.icon-atom::before {
  content: "\efb5";
}

.icon-medal::before {
  content: "\efb6";
}

.icon-backpack-57::before {
  content: "\efb7";
}

.icon-backpack-58::before {
  content: "\efb8";
}

.icon-basketball::before {
  content: "\efb9";
}

.icon-soccer-ball::before {
  content: "\efba";
}

.icon-board-51::before {
  content: "\efbb";
}

.icon-book-39::before {
  content: "\efbc";
}

.icon-book-bookmark-2::before {
  content: "\efbd";
}

.icon-book-open-2::before {
  content: "\efbe";
}

.icon-books-46::before {
  content: "\efbf";
}

.icon-flask::before {
  content: "\efc0";
}

.icon-glasses-2::before {
  content: "\efc1";
}

.icon-hat-3::before {
  content: "\efc2";
}

.icon-language::before {
  content: "\efc3";
}

.icon-molecule::before {
  content: "\efc4";
}

.icon-notepad::before {
  content: "\efc5";
}

.icon-paper-diploma::before {
  content: "\efc6";
}

.icon-paper::before {
  content: "\efc7";
}

.icon-pencil-47::before {
  content: "\efc8";
}

.icon-school::before {
  content: "\efc9";
}

.icon-shuttlecock::before {
  content: "\efca";
}

.icon-baseball-pitch::before {
  content: "\efcb";
}

.icon-baseball-bat::before {
  content: "\efcc";
}

.icon-basketball-ring::before {
  content: "\efcd";
}

.icon-boxing-glove::before {
  content: "\efce";
}

.icon-exercise-bike::before {
  content: "\efcf";
}

.icon-cricket-bat::before {
  content: "\efd0";
}

.icon-crown::before {
  content: "\efd1";
}

.icon-dart::before {
  content: "\efd2";
}

.icon-fencing-swords::before {
  content: "\efd3";
}

.icon-dumbbell::before {
  content: "\efd4";
}

.icon-hook::before {
  content: "\efd5";
}

.icon-chequered-flag::before {
  content: "\efd6";
}

.icon-football-headguard::before {
  content: "\efd7";
}

.icon-golf-club::before {
  content: "\efd8";
}

.icon-whisk::before {
  content: "\efd9";
}

.icon-kettlebell::before {
  content: "\efda";
}

.icon-table-tennis-bat::before {
  content: "\efdb";
}

.icon-podium::before {
  content: "\efdc";
}

.icon-skipping-rope::before {
  content: "\efdd";
}

.icon-rugby-ball::before {
  content: "\efde";
}

.icon-flip-up::before {
  content: "\efdf";
}

.icon-hockey-stick::before {
  content: "\efe0";
}

.icon-rolling-pin::before {
  content: "\efe1";
}

.icon-snowboard::before {
  content: "\efe2";
}

.icon-shaker::before {
  content: "\efe3";
}

.icon-skateboard::before {
  content: "\efe4";
}

.icon-soccer-field::before {
  content: "\efe5";
}

.icon-food-supplement::before {
  content: "\efe6";
}

.icon-surfboard::before {
  content: "\efe7";
}

.icon-tennis-ball::before {
  content: "\efe8";
}

.icon-trophy::before {
  content: "\efe9";
}

.icon-athletics::before {
  content: "\efea";
}

.icon-volleyball::before {
  content: "\efeb";
}

.icon-whistle::before {
  content: "\efec";
}

.icon-backpack-2::before {
  content: "\efed";
}

.icon-bag::before {
  content: "\efee";
}

.icon-camping::before {
  content: "\efef";
}

.icon-drink-2::before {
  content: "\eff0";
}

.icon-explore-2::before {
  content: "\eff1";
}

.icon-fire::before {
  content: "\eff2";
}

.icon-hotel-bell::before {
  content: "\eff3";
}

.icon-hotel-symbol::before {
  content: "\eff4";
}

.icon-hotel::before {
  content: "\eff5";
}

.icon-igloo::before {
  content: "\eff6";
}

.icon-jellyfish::before {
  content: "\eff7";
}

.icon-lamp-3::before {
  content: "\eff8";
}

.icon-luggage::before {
  content: "\eff9";
}

.icon-octopus::before {
  content: "\effa";
}

.icon-passport::before {
  content: "\effb";
}

.icon-pickaxe::before {
  content: "\effc";
}

.icon-pool::before {
  content: "\effd";
}

.icon-beach-bat::before {
  content: "\effe";
}

.icon-road-sign-left::before {
  content: "\efff";
}

.icon-road-sign-right::before {
  content: "\f000";
}

.icon-rowing-oars::before {
  content: "\f001";
}

.icon-snorkel-mask::before {
  content: "\f002";
}

.icon-shark-2::before {
  content: "\f003";
}

.icon-spa::before {
  content: "\f004";
}

.icon-sunglasses::before {
  content: "\f005";
}

.icon-windsurfing::before {
  content: "\f006";
}

.icon-steering-wheel::before {
  content: "\f007";
}

.icon-swiss-knife::before {
  content: "\f008";
}

.icon-trolley::before {
  content: "\f009";
}

.icon-world-2::before {
  content: "\f00a";
}

.icon-celsius::before {
  content: "\f00b";
}

.icon-cloud-drop::before {
  content: "\f00c";
}

.icon-cloud-fog-31::before {
  content: "\f00d";
}

.icon-cloud-fog-32::before {
  content: "\f00e";
}

.icon-cloud-hail::before {
  content: "\f00f";
}

.icon-cloud-light::before {
  content: "\f010";
}

.icon-cloud-moon::before {
  content: "\f011";
}

.icon-cloud-rain::before {
  content: "\f012";
}

.icon-cloud-snow-34::before {
  content: "\f013";
}

.icon-cloud-snow-42::before {
  content: "\f014";
}

.icon-cloud-sun-17::before {
  content: "\f015";
}

.icon-compass-3::before {
  content: "\f016";
}

.icon-drop-15::before {
  content: "\f017";
}

.icon-drops::before {
  content: "\f018";
}

.icon-eclipse::before {
  content: "\f019";
}

.icon-fahrenheit::before {
  content: "\f01a";
}

.icon-fog::before {
  content: "\f01b";
}

.icon-forecast::before {
  content: "\f01c";
}

.icon-hurricane-44::before {
  content: "\f01d";
}

.icon-hurricane-45::before {
  content: "\f01e";
}

.icon-moon-cloud-drop::before {
  content: "\f01f";
}

.icon-moon-cloud-fog::before {
  content: "\f020";
}

.icon-tennis-racket::before {
  content: "\f021";
}

.icon-bikini::before {
  content: "\f022";
}

.icon-moon-cloud-rain::before {
  content: "\f023";
}

.icon-moon-cloud-snow-61::before {
  content: "\f024";
}

.icon-moon-cloud-snow-62::before {
  content: "\f025";
}

.icon-moon-fog::before {
  content: "\f026";
}

.icon-tactic::before {
  content: "\f027";
}

.icon-moon-stars::before {
  content: "\f028";
}

.icon-moon::before {
  content: "\f029";
}

.icon-rain-hail::before {
  content: "\f02a";
}

.icon-rain::before {
  content: "\f02b";
}

.icon-moon-cloud-hail::before {
  content: "\f02c";
}

.icon-moon-full::before {
  content: "\f02d";
}

.icon-sun-cloud-drop::before {
  content: "\f02e";
}

.icon-moon-cloud-light::before {
  content: "\f02f";
}

.icon-snow::before {
  content: "\f030";
}

.icon-sun-cloud-light::before {
  content: "\f031";
}

.icon-sun-cloud-rain::before {
  content: "\f032";
}

.icon-sun-cloud-snow-54::before {
  content: "\f033";
}

.icon-sun-cloud-snow-55::before {
  content: "\f034";
}

.icon-sun-cloud::before {
  content: "\f035";
}

.icon-sun-fog-30::before {
  content: "\f036";
}

.icon-sun-fog-43::before {
  content: "\f037";
}

.icon-wind-2::before {
  content: "\f038";
}

.icon-steak-2::before {
  content: "\f039";
}

.icon-sun-cloud-fog::before {
  content: "\f03a";
}

.icon-sun-cloud-hail::before {
  content: "\f03b";
}

.icon-rainbow::before {
  content: "\f03c";
}

.icon-pancake::before {
  content: "\f03d";
}

.icon-avocado::before {
  content: "\f03e";
}

.icon-cutlery::before {
  content: "\f03f";
}

.icon-terrace::before {
  content: "\f040";
}

.icon-coffee-bean::before {
  content: "\f041";
}

.icon-reservation::before {
  content: "\f042";
}

.icon-restaurant-menu::before {
  content: "\f043";
}

.icon-chef::before {
  content: "\f044";
}

.icon-wine-list::before {
  content: "\f045";
}

.icon-dish::before {
  content: "\f046";
}

.icon-hazelnut::before {
  content: "\f047";
}

.icon-roast-chicken::before {
  content: "\f048";
}

.icon-opening-times::before {
  content: "\f049";
}

.icon-drink-list::before {
  content: "\f04a";
}

.icon-bill::before {
  content: "\f04b";
}

.icon-event-create::before {
  content: "\f04c";
}

.icon-event-confirm::before {
  content: "\f04d";
}

.icon-salad::before {
  content: "\f04e";
}

.icon-recipe::before {
  content: "\f04f";
}

.icon-recipe-create::before {
  content: "\f050";
}

.icon-diet::before {
  content: "\f051";
}

.icon-energy::before {
  content: "\f052";
}

.icon-nutrition::before {
  content: "\f053";
}

.icon-juice::before {
  content: "\f054";
}

.icon-food-scale::before {
  content: "\f055";
}

.icon-diet-plan::before {
  content: "\f056";
}

.icon-snack::before {
  content: "\f057";
}

.icon-detox::before {
  content: "\f058";
}

.icon-tomato::before {
  content: "\f059";
}

.icon-glass-water::before {
  content: "\f05a";
}

.icon-organic::before {
  content: "\f05b";
}

.icon-brioche::before {
  content: "\f05c";
}

.icon-crumpet::before {
  content: "\f05d";
}

.icon-crepe::before {
  content: "\f05e";
}

.icon-waffle::before {
  content: "\f05f";
}

.icon-chocolate-mousse::before {
  content: "\f060";
}

.icon-honey::before {
  content: "\f061";
}

.icon-ice-cream::before {
  content: "\f062";
}

.icon-jelly::before {
  content: "\f063";
}

.icon-cupcake::before {
  content: "\f064";
}

.icon-churros::before {
  content: "\f065";
}

.icon-choco-cream::before {
  content: "\f066";
}

.icon-peanut::before {
  content: "\f067";
}

.icon-picnic-basket::before {
  content: "\f068";
}

.icon-smoothie::before {
  content: "\f069";
}

.icon-pineapple::before {
  content: "\f06a";
}

.icon-lemon::before {
  content: "\f06b";
}

.icon-pear::before {
  content: "\f06c";
}

.icon-orange::before {
  content: "\f06d";
}

.icon-blueberries::before {
  content: "\f06e";
}

.icon-kiwi::before {
  content: "\f06f";
}

.icon-garlic::before {
  content: "\f070";
}

.icon-potato::before {
  content: "\f071";
}

.icon-corn::before {
  content: "\f072";
}

.icon-onion::before {
  content: "\f073";
}

.icon-aubergine::before {
  content: "\f074";
}

.icon-pepper::before {
  content: "\f075";
}

.icon-sugar::before {
  content: "\f076";
}

.icon-bag-delivery::before {
  content: "\f077";
}

.icon-pickle::before {
  content: "\f078";
}

.icon-rice::before {
  content: "\f079";
}

.icon-soup::before {
  content: "\f07a";
}

.icon-lollipop::before {
  content: "\f07b";
}

.icon-soy-sauce::before {
  content: "\f07c";
}

.icon-tea-bag::before {
  content: "\f07d";
}

.icon-popcorn::before {
  content: "\f07e";
}

.icon-timer::before {
  content: "\f07f";
}

.icon-yogurt::before {
  content: "\f080";
}

.icon-organic-2::before {
  content: "\f081";
}

.icon-pasta::before {
  content: "\f082";
}

.icon-ketchup::before {
  content: "\f083";
}

.icon-bakery::before {
  content: "\f084";
}

.icon-mayo::before {
  content: "\f085";
}

.icon-mortar::before {
  content: "\f086";
}

.icon-eggs::before {
  content: "\f087";
}

.icon-can::before {
  content: "\f088";
}

.icon-takeaway::before {
  content: "\f089";
}

.icon-oil-2::before {
  content: "\f08a";
}

.icon-butter::before {
  content: "\f08b";
}

.icon-name-card::before {
  content: "\f08c";
}

.icon-shell::before {
  content: "\f08d";
}

.icon-grain::before {
  content: "\f08e";
}

.icon-boiling-water::before {
  content: "\f08f";
}

.icon-peas::before {
  content: "\f090";
}

.icon-soft-drink::before {
  content: "\f091";
}

.icon-hot-key::before {
  content: "\f092";
}

.icon-switcher::before {
  content: "\f093";
}

.icon-mower::before {
  content: "\f094";
}

.icon-img-stack::before {
  content: "\f095";
}

.icon-check-all::before {
  content: "\f096";
}

.icon-handshake::before {
  content: "\f097";
}

.icon-privacy-policy::before {
  content: "\f098";
}

.icon-ranking::before {
  content: "\f099";
}

.icon-distance::before {
  content: "\f09a";
}

.icon-rings::before {
  content: "\f09b";
}

.icon-water-surface::before {
  content: "\f09c";
}

.icon-water-wave::before {
  content: "\f09d";
}

.icon-golf-ball::before {
  content: "\f09e";
}

.icon-golf-strike::before {
  content: "\f09f";
}

.icon-golf-course::before {
  content: "\f0a0";
}

.icon-olympic-flame::before {
  content: "\f0a1";
}

.icon-water-polo-ball::before {
  content: "\f0a2";
}

.icon-analytics::before {
  content: "\f0a3";
}

.icon-stadium::before {
  content: "\f0a4";
}

.icon-ice-skates::before {
  content: "\f0a5";
}

.icon-bowling-pins::before {
  content: "\f0a6";
}

.icon-boxing-bag::before {
  content: "\f0a7";
}

.icon-curling-stone::before {
  content: "\f0a8";
}

.icon-sports-fan::before {
  content: "\f0a9";
}

.icon-workout-plan::before {
  content: "\f0aa";
}

.icon-bulb-saver::before {
  content: "\f0ab";
}

.icon-bulb::before {
  content: "\f0ac";
}

.icon-car::before {
  content: "\f0ad";
}

.icon-fuel-electric::before {
  content: "\f0ae";
}

.icon-fuel::before {
  content: "\f0af";
}

.icon-smart-home::before {
  content: "\f0b0";
}

.icon-panel::before {
  content: "\f0b1";
}

.icon-power-level::before {
  content: "\f0b2";
}

.icon-radiation::before {
  content: "\f0b3";
}

.icon-recycling::before {
  content: "\f0b4";
}

.icon-save-planet::before {
  content: "\f0b5";
}

.icon-waste-danger::before {
  content: "\f0b6";
}

.icon-waste-recycling::before {
  content: "\f0b7";
}

.icon-waste::before {
  content: "\f0b8";
}

.icon-water-hand::before {
  content: "\f0b9";
}

.icon-water-sink::before {
  content: "\f0ba";
}

.icon-jump-rope::before {
  content: "\f0bb";
}

.icon-horse::before {
  content: "\f0bc";
}

.icon-treadmill::before {
  content: "\f0bd";
}

.icon-weight-plate::before {
  content: "\f0be";
}

.icon-shorts::before {
  content: "\f0bf";
}

.icon-diet-food::before {
  content: "\f0c0";
}

.icon-drinking-bottle::before {
  content: "\f0c1";
}

.icon-wind::before {
  content: "\f0c2";
}

.icon-water::before {
  content: "\f0c3";
}

.icon-leggins::before {
  content: "\f0c4";
}

.icon-tags-stack::before {
  content: "\f0c5";
}

.icon-blender::before {
  content: "\f0c6";
}

.icon-server-rack::before {
  content: "\f0c7";
}

.icon-sport-bag::before {
  content: "\f0c8";
}

.icon-water-aerobics::before {
  content: "\f0c9";
}

.icon-dancer::before {
  content: "\f0ca";
}

.icon-paralympic-games::before {
  content: "\f0cb";
}

.icon-volleyball-player::before {
  content: "\f0cc";
}

.icon-skating::before {
  content: "\f0cd";
}

.icon-water-polo::before {
  content: "\f0ce";
}

.icon-soccer::before {
  content: "\f0cf";
}

.icon-boxing::before {
  content: "\f0d0";
}

.icon-karate::before {
  content: "\f0d1";
}

.icon-cycling-track::before {
  content: "\f0d2";
}

.icon-skiing::before {
  content: "\f0d3";
}

.icon-skateboarding::before {
  content: "\f0d4";
}

.icon-deadlift::before {
  content: "\f0d5";
}

.icon-handball::before {
  content: "\f0d6";
}

.icon-basketball-player::before {
  content: "\f0d7";
}

.icon-bodybuilder::before {
  content: "\f0d8";
}

.icon-stretching::before {
  content: "\f0d9";
}

.icon-fencing::before {
  content: "\f0da";
}

.icon-baseball-player::before {
  content: "\f0db";
}

.icon-archer::before {
  content: "\f0dc";
}

.icon-elliptical-cross-trainer::before {
  content: "\f0dd";
}

.icon-barbell::before {
  content: "\f0de";
}

.icon-row-machine::before {
  content: "\f0df";
}

.icon-stair-climber::before {
  content: "\f0e0";
}

.icon-utility-bench::before {
  content: "\f0e1";
}

.icon-spinning-bike::before {
  content: "\f0e2";
}

.icon-lat-station::before {
  content: "\f0e3";
}

.icon-weight-bench::before {
  content: "\f0e4";
}

.icon-pectoral-machine::before {
  content: "\f0e5";
}

.icon-ez-bar::before {
  content: "\f0e6";
}

.icon-power-rack::before {
  content: "\f0e7";
}

.icon-medicine-ball::before {
  content: "\f0e8";
}

.icon-towel::before {
  content: "\f0e9";
}

.icon-gym::before {
  content: "\f0ea";
}

.icon-bicep::before {
  content: "\f0eb";
}

.icon-man-up-front::before {
  content: "\f0ec";
}

.icon-woman-up-front::before {
  content: "\f0ed";
}

.icon-measurement::before {
  content: "\f0ee";
}

.icon-body-back::before {
  content: "\f0ef";
}

.icon-body-butt::before {
  content: "\f0f0";
}

.icon-equestrian-helmet::before {
  content: "\f0f1";
}

.icon-sports-bra::before {
  content: "\f0f2";
}

.icon-mat::before {
  content: "\f0f3";
}

.icon-users-wm::before {
  content: "\f0f4";
}

.icon-users-mm::before {
  content: "\f0f5";
}

.icon-users-ww::before {
  content: "\f0f6";
}

.icon-referee::before {
  content: "\f0f7";
}

.icon-personal-trainer::before {
  content: "\f0f8";
}

.icon-presentation::before {
  content: "\f0f9";
}

.icon-hockey::before {
  content: "\f0fa";
}

.icon-tennis::before {
  content: "\f0fb";
}

.icon-check-double::before {
  content: "\f0fc";
}

.icon-check-single::before {
  content: "\f0fd";
}

.icon-progress-indicator::before {
  content: "\f0fe";
}

.icon-video-gallery::before {
  content: "\f0ff";
}

.icon-geometry::before {
  content: "\f100";
}

.icon-apps::before {
  content: "\f101";
}

.icon-a-tag::before {
  content: "\f102";
}

.icon-a-tag-add::before {
  content: "\f103";
}

.icon-a-tag-remove::before {
  content: "\f104";
}

.icon-questionnaire::before {
  content: "\f105";
}

.icon-check-list::before {
  content: "\f106";
}

.icon-survey::before {
  content: "\f107";
}

.icon-app-store::before {
  content: "\f108";
}

.icon-saved-items::before {
  content: "\f109";
}

.icon-save-to-list::before {
  content: "\f10a";
}

.icon-star-rate::before {
  content: "\f10b";
}

.icon-bookmarks::before {
  content: "\f10c";
}

.icon-feedback::before {
  content: "\f10d";
}

.icon-backward::before {
  content: "\f10e";
}

.icon-layout-grid::before {
  content: "\f10f";
}

.icon-layout::before {
  content: "\f110";
}

.icon-wireframe::before {
  content: "\f111";
}

.icon-slide-left::before {
  content: "\f112";
}

.icon-slide-right::before {
  content: "\f113";
}

.icon-forward::before {
  content: "\f114";
}
